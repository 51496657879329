import { axiosInstance } from "@/api/axios.instance";
import { auth } from "@/config/firebase";
import { AxiosRequestConfig } from "axios";
import { useCallback } from "react";
import { useAuthState } from "react-firebase-hooks/auth";

export const useRequestInterceptor = () => {
      const [user] = useAuthState(auth);

      const requestInterceptor = useCallback(() => {
            try {
                  axiosInstance.interceptors.request.use(
                        async (config: AxiosRequestConfig) => {
                              if (config.headers && user) {
                                    const accessToken = await user.getIdToken();
                                    config.headers.Authorization = "Bearer " + accessToken;
                                    const countryCode = localStorage.getItem('countryCode');
                                    if (countryCode) {
                                          config.headers['country-code'] = countryCode;
                                    }
                                    /* config.headers["Access-Control-Allow-Origin"] = "http://localhost:3000";
                                          config.headers["Access-Control-Allow-Methods"] =
                                           "GET, POST, PUT, DELETE, OPTIONS";
                                          config.headers["Access-Control-Allow-Headers"] =
                                           "Content-Type, Authorization"; */
                              }
                              return config;
                        },
                  );
            } catch (error) {
                  console.log(error);
            }
      }, [user]);

      return requestInterceptor;
};

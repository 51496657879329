import { Toast } from "primereact";
import { ICharity } from "@/models";
import { orderCharities } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useOrderCharitiesData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading: orderCharitiesIsLoading } = useMutation(
        async (charities: Array<Omit<ICharity, "imageUrl">>) => await orderCharities(charities),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("charities");
                toastSuccess(toast, "Charities ordered");
            },
            onError: (error: any) => {
                let errorMessage = "Charities not ordered";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return {
        mutate,
        orderCharitiesIsLoading,
    };
};

import { useQuery } from "react-query";
import { getCharity } from "@/services";

export const useGetCharityData = (charityId: number | null) => {
    const {
        data: charity,
        isLoading: getCharityIsLoading,
        refetch: refetchCharity,
    } = useQuery(["charity", charityId], getCharity, {
        enabled: false,
    });
    return { charity, getCharityIsLoading, refetchCharity };
};

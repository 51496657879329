import { Toast } from "primereact";
import { deleteCharityImage } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useDeleteCharityImageData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading: deleteCharityImageIsLoading } = useMutation(
        async (imageId: bigint | number) => await deleteCharityImage(imageId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("charities");
                toastSuccess(toast, "Charity image deleted");
            },
            onError: (error: any) => {
                let errorMessage = "Charity image not deleted";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return {
        mutate,
        deleteCharityImageIsLoading,
    };
};

import {
    ErrorField,
    InputField,
    DataTableDialogFooter,
    DropdownField,
    InputNumberField,
    MultiSelectField,
} from "@/components";
import { initialFootnote, statusTypes } from "@/constants";
import { PRODUCT_TYPES } from "@/constants/enums";
import { useGetFootnotesData } from "@/hooks";
import { ICategory, IProduct } from "@/models";
import { capitalizeFirstLetter, isFormFieldValid } from "@/utils";
import { productSchema } from "@/validations";
import classNames from "classnames";
import { useFormik } from "formik";
import { Badge, Column, DataTable, Dialog, InputSwitch, TabMenu } from "primereact";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface ProductDialogProps {
    visible: boolean;
    product: Omit<IProduct, "imageUrl">;
    categories: ICategory[] | undefined;
    isLoading: boolean;
    isAddItem: boolean;
    onHide: () => void;
    addProduct: (product: Omit<IProduct, "imageUrl">) => void;
}

const types = [PRODUCT_TYPES.DIGITAL, PRODUCT_TYPES.PHYSICAL];

const items = [
    { label: "Main", icon: "pi pi-fw pi-home" },
    { label: "Second Language", icon: "pi pi-fw pi-language" },
    { label: "Label", icon: "pi pi-fw pi-tag" },
    { label: "T&C", icon: "pi pi-fw pi-check-circle" },
];

const statusOptions = statusTypes?.map((status) => ({
    label: status,
    value: status,
}));

const colorOptions = [
    { label: "", value: null },
    { label: "PURPLE", value: "#331074" },
    { label: "RED", value: "#E42626" },
    { label: "DARK BLUE", value: "#1D3183" },
    { label: "INDIGO", value: "#4B0082" },
    { label: "ORANGE", value: "#FFA500" },
    { label: "GREEN", value: "#008000" },

];

const ProductDialog: React.FC<ProductDialogProps> = ({
    visible,
    product,
    isLoading,
    categories,
    isAddItem,
    onHide,
    addProduct,
}) => {
    const { t } = useTranslation();
    const [activeIndex, setActiveIndex] = useState(0);

    const [selectedFootnote, setSelectedFootnote] = useState(initialFootnote);
    const [updatedFootNote, setUpdatedFootNote] = useState('');
    const [updatedFootNote2lng, setUpdatedFootNote2lng] = useState('');


    const { refetchGetFootnotes, footnotes } = useGetFootnotesData(false);

    const footNoteOptions = footnotes?.map((footnote) => ({
        label: footnote.name,
        value: footnote,
    }));

    const categoriesOptions = categories?.map((category) => ({
        label: category.title,
        value: category,
    }));

    const typeOptions = types?.map((type) => ({
        label: type,
        value: type,
    }));

    function removeTrailingPipes(inputString: string) {
        // Sağ taraftaki boşlukları temizle
        inputString = inputString.trimEnd();

        // Sağdan başlayarak "|" karakterlerini kaldır
        while (inputString.endsWith('|')) {
            inputString = inputString.slice(0, -1);
        }

        inputString = inputString.replace(/\|+/g, '|');

        return inputString;
    }

    const formik = useFormik({
        initialValues: product,
        enableReinitialize: true,
        validationSchema: productSchema,
        onSubmit: (data) => {
            if (!(data?.labelColor?.includes('#'))) {
                data.labelColor = data?.labelColor !== null ? '#' + data?.labelColor : null
            }

            data.footNote = removeTrailingPipes(updatedFootNote ?? '') === "" ? "" : `|${removeTrailingPipes(updatedFootNote ?? '')}`;
            data.footNote2lng = removeTrailingPipes(updatedFootNote2lng ?? '') === "" ? "" : `|${removeTrailingPipes(updatedFootNote2lng ?? '')}`;

            addProduct(data);
        },
    });

    useEffect(() => {
        if (visible) {
            refetchGetFootnotes();
            // setSelectedFootnote(footNoteOptions?.find((e) => e.label === formik.values.footNoteName)?.value ?? initialFootnote)
            setUpdatedFootNote(`${(formik.values.footNote ?? '').replace(/^(\|)*/, '')}|`);
            setUpdatedFootNote2lng(`${(formik.values.footNote2lng ?? '').replace(/^(\|)*/, '')}|`);
        }
    }, [visible]);

    useEffect(() => {
        if (selectedFootnote) {
            formik.setValues({
                ...formik.values,
                footNote: selectedFootnote.description ?? "",
                footNote2lng: selectedFootnote.description2lng ?? "",
                footNoteName: selectedFootnote.name ?? "",
            });
            setUpdatedFootNote(`${(selectedFootnote.description ?? "").replace(/^(\|)*/, '')}|`);
            setUpdatedFootNote2lng(`${(selectedFootnote.description2lng ?? "").replace(/^(\|)*/, '')}|`);
        }
    }, [selectedFootnote]);

    const hideDialog = () => {
        formik.resetForm();
        onHide();
    };

    const onDescCellEditComplete = (e: any) => {
        const { rowData, newValue, field, originalEvent: event } = e;
        const updatedData = [...updatedFootNote?.split('|') ?? []];
        const index = updatedData.findIndex((data) => rowData[field] === data)
        updatedData[index] = newValue;
        const newUpdatedData = updatedData.join('|');
        if (newValue.trim().length > 0) rowData[field] = newValue;
        else event.preventDefault();
        setUpdatedFootNote(`${removeTrailingPipes(newUpdatedData)}|`);
    };

    const onDesc2lngCellEditComplete = (e: any) => {
        const { rowData, newValue, field, originalEvent: event } = e;
        const updatedData = [...updatedFootNote2lng?.split('|') ?? []];
        const index = updatedData.findIndex((data) => rowData[field] === data)
        updatedData[index] = newValue;
        const newUpdatedData = updatedData.join('|');
        if (newValue.trim().length > 0) rowData[field] = newValue;
        else event.preventDefault();
        setUpdatedFootNote2lng(`${removeTrailingPipes(newUpdatedData)}|`);
    };

    const textEditor = (options: any) => {
        return <InputField type="text" value={options.value} onChange={(e) => options.editorCallback(e.target.value)} labelName={""} labelText={""} multiline />;
    };

    return (
        <Dialog
            visible={visible}
            style={{ width: "800px" }}
            header={t("Product Details")}
            modal
            className="p-fluid"
            footer={
                <DataTableDialogFooter
                    loading={isLoading}
                    hideDialog={hideDialog}
                    handleSubmit={formik.handleSubmit}
                />
            }
            onHide={hideDialog}
        >
            <TabMenu
                model={items}
                className="mb-5"
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
            />
            <form onSubmit={formik.handleSubmit}>
                {activeIndex === 0 ? (
                    <>
                        <InputField
                            id="name"
                            value={formik.values.name ?? ""}
                            type="text"
                            required
                            autoFocus={true}
                            onChange={formik.handleChange}
                            labelName="name"
                            labelText={capitalizeFirstLetter("name")}
                            getFormErrorMessage={() => <ErrorField formik={formik} name="name" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "name"),
                            })}
                        />
                        <DropdownField
                            id="type"
                            name="type"
                            value={formik.values.type}
                            onChange={formik.handleChange}
                            options={typeOptions ?? []}
                            labelName="type"
                            labelText={capitalizeFirstLetter("type")}
                            getFormErrorMessage={() => <ErrorField formik={formik} name="type" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "type"),
                            })}
                        />
                        <InputField
                            id="brand"
                            value={formik.values.brand ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="brand"
                            labelText={capitalizeFirstLetter("brand")}
                            getFormErrorMessage={() => <ErrorField formik={formik} name="brand" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "brand"),
                            })}
                        />
                        <InputField
                            id="longDesc"
                            value={formik.values.longDesc ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="longDesc"
                            labelText={capitalizeFirstLetter("Long Description")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="longDesc" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "longDesc"),
                            })}
                            multiline
                        />
                        <InputField
                            id="shortDesc"
                            value={formik.values.shortDesc ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="shortDesc"
                            labelText={capitalizeFirstLetter("Short Description")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="shortDesc" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "shortDesc"),
                            })}
                        />
                        <InputNumberField
                            id="sellingPoint"
                            inputId="sellingPoint"
                            showButtons
                            mode="currency"
                            currency="SMP"
                            value={formik.values.sellingPoint ?? ""}
                            required
                            onValueChange={formik.handleChange}
                            labelName="sellingPoint"
                            labelText={capitalizeFirstLetter("Price")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="sellingPoint" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "sellingPoint"),
                            })}
                        />
                        <InputNumberField
                            id="yearlyPurchaseLimit"
                            inputId="yearlyPurchaseLimit"
                            showButtons
                            value={formik.values.yearlyPurchaseLimit ?? ""}
                            required
                            onValueChange={formik.handleChange}
                            labelName="yearlyPurchaseLimit"
                            labelText={capitalizeFirstLetter("Yearly Purchase Limit")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="yearlyPurchaseLimit" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "yearlyPurchaseLimit"),
                            })}
                        />
                        <MultiSelectField
                            id="categories"
                            name="categories"
                            value={formik.values.categories}
                            multiple={true}
                            onChange={formik.handleChange}
                            options={categoriesOptions ?? []}
                            filter={true}
                            labelName="categories"
                            labelText={capitalizeFirstLetter("categories")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="categories" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "categories"),
                            })}
                        />
                        <InputField
                            id="productLink"
                            value={formik.values.productLink ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="productLink"
                            labelText={capitalizeFirstLetter("Product Link")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="productLink" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "productLink"),
                            })}
                        />
                        {!isAddItem && (
                            <DropdownField
                                id="status"
                                name="status"
                                value={formik.values.status ?? ""}
                                required
                                autoFocus={true}
                                onChange={formik.handleChange}
                                options={statusOptions}
                                filter={true}
                                labelName="status"
                                labelText={capitalizeFirstLetter("status")}
                                getFormErrorMessage={() => <ErrorField formik={formik} name="status" />}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "status"),
                                })}
                            />
                        )}
                        <InputNumberField
                            id="stockQuantity"
                            inputId="stockQuantity"
                            showButtons
                            value={formik.values.stockQuantity ?? ""}
                            required
                            onValueChange={formik.handleChange}
                            labelName="stockQuantity"
                            labelText={capitalizeFirstLetter("Stock Amount")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="stockQuantity" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "stockQuantity"),
                            })}
                        />
                        <InputNumberField
                            id="discountValue"
                            inputId="discountValue"
                            showButtons
                            value={formik.values.discountValue}
                            required
                            onValueChange={formik.handleChange}
                            labelName="discountValue"
                            labelText="Discount Percentage"
                            max={100}
                            min={0}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="discountValue" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "discountValue"),
                            })}
                        />
                        <InputNumberField
                            id="discountedPrice"
                            inputId="discountedPrice"
                            showButtons
                            value={formik.values.discountedPrice}
                            required
                            onValueChange={formik.handleChange}
                            labelName="discountedPrice"
                            min={0}
                            labelText="Discounted Price"
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="discountedPrice" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "discountedPrice"),
                            })}
                        />
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                            <span>Show Stock Amount</span>
                            <InputSwitch
                                id="showStockAmount"
                                checked={formik.values.showStockAmount ?? false}
                                onChange={formik.handleChange}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "showStockAmount"),
                                })}
                            />
                        </div>
                    </>
                ) : activeIndex === 1 ? (
                    <>
                        <InputField
                            id="name2lgn"
                            value={formik.values.name2lgn ?? ""}
                            type="text"
                            required
                            autoFocus={true}
                            onChange={formik.handleChange}
                            labelName="name2lgn"
                            labelText={capitalizeFirstLetter("name")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="name2lgn" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "name2lgn"),
                            })}
                        />
                        <InputField
                            id="brand2lng"
                            value={formik.values.brand2lng ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="brand2lng"
                            labelText={capitalizeFirstLetter("brand")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="brand2lng" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "brand2lng"),
                            })}
                        />
                        <InputField
                            id="longDesc2lng"
                            value={formik.values.longDesc2lng ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="longDesc2lng"
                            labelText={capitalizeFirstLetter("Long Description")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="longDesc2lng" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "longDesc2lng"),
                            })}
                            multiline
                        />
                        <InputField
                            id="shortDesc2lng"
                            value={formik.values.shortDesc2lng ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="shortDesc2lng"
                            labelText={capitalizeFirstLetter("Short Description")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="shortDesc2lng" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "shortDesc2lng"),
                            })}
                        />
                    </>
                ) : activeIndex === 2 ? (
                    <>
                        <InputField
                            id="labelText"
                            value={formik.values.labelText ?? ""}
                            type="text"
                            required
                            autoFocus={true}
                            onChange={formik.handleChange}
                            labelName="labelText"
                            labelText={capitalizeFirstLetter("text")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="labelText" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "labelText"),
                            })}
                        />
                        <DropdownField
                            id="labelColor"
                            value={formik.values.labelColor}
                            type="text"
                            required
                            options={colorOptions}
                            onChange={formik.handleChange}
                            labelName="labelColor"
                            labelText={capitalizeFirstLetter("color")}
                            getFormErrorMessage={() => <ErrorField formik={formik} name={"labelColor"} />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "labelColor"),
                            })}
                            itemTemplate={(option) => {
                                return option?.value !== null ?
                                    <Badge value={option?.label} style={{ backgroundColor: option?.value }} />
                                    : null;
                            }}
                            valueTemplate={(option) => {
                                return option?.value !== null ?
                                    <Badge value={option?.label} style={{ backgroundColor: option?.value }} />
                                    : null;
                            }}
                        />
                    </>
                ) : (
                    <>
                        <DropdownField
                            id="footNoteName"
                            value={footNoteOptions?.find((option) => option.label === formik.values.footNoteName)?.value ?? ""}
                            type="text"
                            required
                            options={footNoteOptions ?? []}
                            onChange={(e) => setSelectedFootnote(e.value)}
                            labelName="footNoteName"
                            labelText="Name"
                            getFormErrorMessage={() => <ErrorField formik={formik} name={"footNoteName"} />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "footNoteName"),
                            })}
                        />

                        <DataTable
                            value={
                                (updatedFootNote?.split('|') ?? []).map((footnote, index) => ({
                                    id: index,
                                    footNote: footnote,
                                    footNote2lng: (updatedFootNote2lng?.split('|') ?? [])[index],
                                }))

                            }
                            editMode="cell"
                            tableStyle={{ minWidth: '50rem' }}
                            responsiveLayout="scroll"
                            showGridlines
                        >
                            <Column
                                field="footNote"
                                header="Description"
                                style={{ width: '50%' }}
                                editor={(options) => textEditor(options)}
                                onCellEditComplete={onDescCellEditComplete}
                            />
                            <Column
                                field="footNote2lng"
                                header="Description Second"
                                style={{ width: '50%' }}
                                editor={(options) => textEditor(options)}
                                onCellEditComplete={onDesc2lngCellEditComplete}
                            />
                        </DataTable>
                    </>
                )
                }
            </form>
        </Dialog>
    );
};

export default ProductDialog;

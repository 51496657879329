import { Toast } from "primereact";
import { addCharityImages } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useAddCharityImagesData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const { mutate,
        isLoading: addCharityImagesIsLoading,
        isSuccess: addCharityImagesIsSuccess
    } = useMutation(
        async (charityImages: FormData) => await addCharityImages(charityImages),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("charities");
                toastSuccess(toast, "Charity images added");
            },
            onError: (error: any) => {
                let errorMessage = "Charity images not added";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return { mutate, addCharityImagesIsLoading, addCharityImagesIsSuccess };
};

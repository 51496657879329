import { auth } from "@/config/firebase";
import { logoutUser } from "@/services";

const generateProfileItems = (profileInfo: any) => {
    return [
        {
            label: profileInfo?.email,
            icon: "pi pi-user",
        },
        {
            separator: true,
        },
        {
            label: "Log Out",
            icon: "pi pi-sign-out",
            command: async () => await logoutUser(auth),
        },
    ];
};

export default generateProfileItems;

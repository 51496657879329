import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { TRANSLATIONS_EN } from "./en/translation";
import LanguageDetector from "i18next-browser-languagedetector";

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        debug: false,
        fallbackLng: "en",
        resources: {
            en: {
                translation: TRANSLATIONS_EN,
            },
        },
    });

import { Toast } from "primereact";
import { addCharityShareImage } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useAddCharityShareImageData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const {
        mutate,
        isLoading: addCharityShareImageIsLoading,
        isSuccess: addCharityShareImageIsSuccess,
    } = useMutation(
        async ({ charityShareImage }: {
            charityShareImage: FormData,
        }) => {
            await addCharityShareImage(charityShareImage);
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries("charities");
                toastSuccess(toast, "Charity web page image added");
            },
            onError: (error: any) => {
                let errorMessage = "Charity web page image added";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return { mutate, addCharityShareImageIsLoading, addCharityShareImageIsSuccess };
};

import moment from "moment";
import { ICharity } from "@/models";
import React, { useState } from "react";
import { Button, Dialog } from "primereact";
import Donor from "@/pages/Charities/Donor";

interface CharityInfoDialogProps {
    visible: boolean;
    onHide: () => void;
    charity: ICharity | null;
}

const CharityInfoDialog: React.FC<CharityInfoDialogProps> = ({
    visible,
    onHide,
    charity,
}) => {

    const [donorListDialog, setDonorListDialog] = useState(false);

    const openDonorListDialog = async () => {
        setDonorListDialog(true);
    };

    const hideDonorListDialog = () => {
        setDonorListDialog(false);
    };

    const cellStyle = {
        display: "table-cell",
        border: "1px solid black",
        padding: "0.5rem",
    };
    const headerCellStyle = {
        ...cellStyle,
        fontWeight: "bold",
    };
    const tableRowStyle = {
        display: "table-row",
    };
    const tableStyle: React.CSSProperties = {
        display: "table",
        borderCollapse: "collapse",
        width: "100%",
    };

    return (
        <>
            <Dialog visible={visible} onHide={onHide} header="Info" style={{ maxWidth: "60%" }}>
                {charity && (
                    <div style={tableStyle}>
                        <div style={tableRowStyle}>
                            <div style={headerCellStyle}>Institution Name</div>
                            <div style={cellStyle}>{charity.charityInstitutionName}</div>
                        </div>
                        <div style={tableRowStyle}>
                            <div style={headerCellStyle}>Name</div>
                            <div style={cellStyle}>{charity.name}</div>
                        </div>
                        <div style={tableRowStyle}>
                            <div style={headerCellStyle}>Donation Count</div>
                            <div style={cellStyle}>{charity.charityDonationCount}</div>
                        </div>
                        <div style={tableRowStyle}>
                            <div style={headerCellStyle}>Actual Value</div>
                            <div style={cellStyle}>{charity.charityActualValue}</div>
                        </div>
                        <div style={tableRowStyle}>
                            <div style={headerCellStyle}>Target Value</div>
                            <div style={cellStyle}>{charity.charityTargetValue}</div>
                        </div>
                        <div style={tableRowStyle}>
                            <div style={headerCellStyle}>Donation Info</div>
                            <div style={cellStyle}>{charity.shortDesc}</div>
                        </div>
                        <div style={tableRowStyle}>
                            <div style={headerCellStyle}>Creation Date</div>
                            <div style={cellStyle}>
                                {moment(charity.creationDate).format("DD-MM-YYYY")}
                            </div>
                        </div>
                        <div style={tableRowStyle}>
                            <div style={headerCellStyle}>Donor List</div>
                            <div style={cellStyle}><Button
                                id="p-ticket-list-button"
                                icon="pi pi-money-bill"
                                className="p-button p-button-primary m-0"
                                style={{ height: 30, width: 50 }}
                                onClick={openDonorListDialog}
                            /></div>
                        </div>
                    </div>
                )}
            </Dialog>

            <Dialog
                visible={donorListDialog}
                onHide={hideDonorListDialog}
                header={'Donor List'}
            >
                <div className="datatable-crud">
                    <Donor charity={charity} />
                </div>
            </Dialog>

        </>
    );
};

export default CharityInfoDialog;

import { Toast } from "primereact";
import { deleteProduct } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useDeleteProductData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const { mutate, isLoading: deleteProductIsLoading } = useMutation(
        async (productId: number) => await deleteProduct(productId),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("products");
                toastSuccess(toast, "Product deleted");
            },
            onError: (error: any) => {
                let errorMessage = "Product not deleted";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return {
        mutate,
        deleteProductIsLoading,
    };
};

import React from "react";
import { IComponentProps } from "@/models";
import { AppMenu, AppTopbar } from "@/components";
import { MainContainer, MainLayout } from "@/layouts";
import { CountryCodeProvider } from "@/contexts/CountryContext";

interface DashboardWrapperProps extends IComponentProps { }

const DashboardWrapper: React.FC<DashboardWrapperProps> = ({ children }) => {
    return (
        <CountryCodeProvider>
            <div>
                <AppTopbar />
                <AppMenu />
                <MainContainer>
                    <MainLayout>{children}</MainLayout>
                </MainContainer>
            </div>
        </CountryCodeProvider>
    );
};

export default DashboardWrapper;
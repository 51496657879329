// dev
// export const getBaseUrl = () => {
//   let url;
//   switch (process.env.NODE_ENV) {
//     case "production":
//       url = "http://155.133.27.34:5555";
//       break;
//     case "development":
//       url = "http://155.133.27.34:5555";
//       break;
//     default:
//       url = "http://155.133.27.34:5555";
//   }
//   return url;>>>>>
// };

// test
export const getBaseUrl = () => {
  let url;
  switch (process.env.NODE_ENV) {
    case "production":
      url = "https://apimng.sm-app.site";
      break;
    case "development":
      url = "https://apimng.sm-app.site";
      break;
    default:
      url = "https://apimng.sm-app.site";
  }
  return url;
};

// // prod
// export const getBaseUrl = () => {
//   let url;
//   switch (process.env.NODE_ENV) {
//     case "production":
//       url = "https://apimng.stepmate.app";
//       break;
//     case "development":
//       url = "https://apimng.stepmate.app";
//       break;
//     default:
//       url = "https://apimng.stepmate.app";
//   }
//   return url;
// };

import moment from "moment";
import { Menu } from "primereact";
import { isDesktop } from "@/utils";
import { Link } from "react-router-dom";
import logoDark from "@assets/images/logo-dark.svg";
import logoLight from "@assets/images/logo-white.svg";
import { TopbarMenuItems, TopbarMenu } from "@/components";
import React, { useState, useRef, useEffect } from "react";
import { useCountryCode } from "@/contexts/CountryContext";
import { appName, CONFIG_VARS, generateProfileItems, ROLE } from "@constants/index";
import { useGetProfileInfoData, useMenuToggle, useMobileMenuToggle, useRole, useTheme } from "@/hooks";

const AppTopbar = () => {
    const { role } = useRole();
    const { countryCode, setCountryCode } = useCountryCode();

    const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
    const profileMenuRef = useRef<Menu>(null);
    const { setMenuIsActive } = useMenuToggle();
    const { setMobileMenuIsActive } = useMobileMenuToggle();
    const { layoutColorMode, setLayoutColorMode } = useTheme();
    const { profileInfo } = useGetProfileInfoData();
    const dropdownRef = useRef<Menu>(null);

    const countries = [
        { label: 'Indonesia', value: 'ID' },
        { label: 'United Kingdom', value: 'UK' },
    ];

    useEffect(() => {
        if (profileInfo?.countryCode && !countryCode) {
            setCountryCode(profileInfo.countryCode);
        }
    }, [profileInfo, countryCode, setCountryCode]);

    const handleCountryChange = (e: any) => {
        setCountryCode(e.value);
        localStorage.setItem('countryCode', e.value);
        window.location.reload();
    };

    const menuItems = countries.map(country => ({
        label: country.label,
        command: (e: any) => handleCountryChange({ value: country.value })
    }));

    const onMobileTopbarMenuClick = (event: React.MouseEvent) => {
        event.preventDefault();
        setMobileTopbarMenuActive((prevState) => !prevState);
    };

    const onSideBarMenuClick = (event: React.MouseEvent) => {
        event.preventDefault();
        setMenuIsActive((prevState) => !prevState);
        !isDesktop() && setMobileMenuIsActive((prevState) => !prevState);
    };

    const changeTheme = () => {
        setLayoutColorMode((prevState) =>
            prevState === CONFIG_VARS.LIGHT ? CONFIG_VARS.DARK : CONFIG_VARS.LIGHT,
        );
    };

    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div className="layout-topbar">
            <Link to="/" className="layout-topbar-logo">
                <img
                    src={layoutColorMode === CONFIG_VARS.LIGHT ? logoDark : logoLight}
                    alt="logo"
                />
                <span
                    onClick={(e) => {
                        if (dropdownRef.current) {
                            dropdownRef.current.toggle(e);
                        }
                    }}
                    style={{ cursor: 'pointer', marginLeft: '8px' }}
                >
                    {appName + '-' + (countryCode ?? profileInfo?.countryCode ?? '')}
                    {role === ROLE.ADMIN && <i className="pi pi-arrow-down" style={{ marginLeft: '8px' }}></i>}
                </span>
                {role === ROLE.ADMIN && <Menu model={menuItems} popup ref={dropdownRef} id="country_menu" />}
            </Link>

            <button
                type="button"
                className="p-link layout-menu-button layout-topbar-button"
                onClick={(e) => onSideBarMenuClick(e)}
            >
                <i className="pi pi-bars" />
            </button>

            <button
                type="button"
                className="p-link layout-topbar-menu-button layout-topbar-button"
                onClick={(e) => onMobileTopbarMenuClick(e)}
            >
                <i className="pi pi-ellipsis-v" />
            </button>

            <div style={{ padding: '1rem' }}>
                {moment(currentTime.toLocaleTimeString("en-US", {
                    timeZone: countryCode === 'UK' ? 'Europe/London' : 'Asia/Jakarta',
                    hour12: false,
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                })).format("DD-MM-YYYY / HH:mm:ss")}
            </div>

            <TopbarMenu active={mobileTopbarMenuActive}>
                <TopbarMenuItems
                    title="Theme"
                    iconClassName="pi pi-sun"
                    onClick={() => changeTheme()}
                />
                {/* <TopbarMenuItems title="Settings" iconClassName="pi pi-cog" /> */}
                <TopbarMenuItems
                    title="Profile"
                    iconClassName="pi pi-user"
                    onClick={(e) => profileMenuRef.current?.toggle(e)}
                />
            </TopbarMenu>

            <Menu ref={profileMenuRef} model={generateProfileItems(profileInfo)} popup />
        </div>
    );
};

export default AppTopbar;

import {
    DashboardCard,
    DashboardHeader,
    DrawAnalyticCard,
    NewOrderAnalyticCard,
    CustomerCountByCkpChart,
    AdsWatchCountPerDayChart,
    CampaignsUsageCountsChart,
    PromotionCodeAnalyticCard,
    CustomerCountOneMonthChart,
    TopTenCustomersAnalyticCard,
} from "@/components";
import {
    useRole,
    useGetOrdersData,
    useGetOrderListData,
    useGetRetentionData,
    useGetCustomerCountData,
    useGetAdsCountWithDateData,
    useGetCountWithMoneyBetweenData,
} from "@/hooks";
import { useTranslation } from "react-i18next";
import { Col, Grid } from "@/layouts";
import { useState } from "react";
import WeeklyRegisteredCustomerCount from "@/components/Chart/WeeklyRegisteredCustomerCount";

const MainDashboard = () => {
    const [moneyAmount, setMoneyAmount] = useState({
        moneyAmountFirst: 100,
        moneyAmountSecond: 200,
    });
    const { moneyAmountFirst, moneyAmountSecond } = moneyAmount;

    const { orderList } = useGetOrderListData();
    const { orders, getOrdersIsLoading } = useGetOrdersData();
    const { customerCount, getCustomerCountIsLoading } = useGetCustomerCountData();
    const { adsCountWithDate, getAdsCountWithDateIsLoading } =
        useGetAdsCountWithDateData();
    const { retention, getRetentionIsLoading } =
        useGetRetentionData();
    const { countWithMoneyBetween, getCountWithMoneyBetweenLoading } =
        useGetCountWithMoneyBetweenData({
            moneyAmountFirst,
            moneyAmountSecond,
        });
    const { t } = useTranslation();
    const { role } = useRole();

    const adsPerUser =
        (adsCountWithDate?.numberOfAdsViewPerDay ?? 0) /
        (adsCountWithDate?.userCount ?? 1);

    return (
        <>
            <Grid className="m-0">
                <Col className="col-12 flex align-items-center m-0 py-10">
                    <DashboardHeader role={role} />
                </Col>
                <DashboardCard
                    title="Orders"
                    count={`${orderList?.length ?? 0} New`}
                    icon="pi-shopping-cart"
                    iconTextColor="text-blue-500"
                    iconBgColor="bg-blue-100"
                    footerTitle={`${orders?.length ?? 0} Total Orders`}
                    className="col-12 lg:col-3"
                    isLoading={getOrdersIsLoading}
                />
                <DashboardCard
                    title="Users"
                    count={`${customerCount?.activeCustomerCount ?? 0} Active`}
                    icon="pi-users"
                    iconTextColor="text-orange-500"
                    iconBgColor="bg-orange-100"
                    className="col-12 lg:col-3"
                    footerTitle={`${customerCount?.totalCustomerCount ?? 0} Total Users`}
                    isLoading={getCustomerCountIsLoading}
                />
                <DashboardCard
                    title="Advertisements"
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    count={`${adsCountWithDate?.numberOfAdsViewPerDay ?? 0} Watched`}
                    icon="pi-inbox"
                    iconTextColor="text-cyan-500"
                    iconBgColor="bg-cyan-100"
                    className="col-12 lg:col-3"
                    footerTitle={`${(adsCountWithDate?.userCount as number) ?? 0} User - ${isNaN(adsPerUser) ? 0 : adsPerUser.toFixed(1)
                        } Ads per  User`}
                    isLoading={getAdsCountWithDateIsLoading}
                />

                <DashboardCard
                    title="Retentions"
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    count={`${retention?.[0]?.[1]?.toFixed(0) ?? 0}% - ${retention?.[1]?.[1]?.toFixed(0) ?? 0}% - ${retention?.[2]?.[1]?.toFixed(0) ?? 0}%`}
                    icon="pi-arrow-up"
                    iconTextColor="text-indigo-500"
                    iconBgColor="bg-indigo-100"
                    className="col-12 lg:col-3"
                    isLoading={getRetentionIsLoading}
                    footerTitle={'Ret 1 - Ret 3 - Ret 7'}
                />

                <Col className="col-12 xl:col-6 px-0">
                    <Col className="col-12 m-0">
                        <CustomerCountOneMonthChart />
                    </Col>
                    {/* <ProductQuantityAnalyticCard /> */}
                    <Col className="col-12 m-0">
                        <WeeklyRegisteredCustomerCount />
                    </Col>
                </Col>
                <Col className="col-12 xl:col-6 px-0">
                    <Col className="col-12 m-0">
                        <AdsWatchCountPerDayChart />
                    </Col>
                    {/* <Col className="col-12 m-0">
                        <AdsWatchedAndNeverWatchedCustomerCountChart />
                    </Col> */}
                    <Col className="col-12 m-0">
                        <CustomerCountByCkpChart />
                    </Col>
                </Col>
                {/* <Col className="col-12 m-0">
                    <AdsWatchCountByActionTypeChart />
                </Col> */}

                <Col className="col-12 m-0">
                    <CampaignsUsageCountsChart />
                </Col>
                <Col className="col-12 xl:col-6 px-0">
                    <NewOrderAnalyticCard />
                    <DrawAnalyticCard />
                </Col>
                <Col className="col-12 xl:col-6 px-0">
                    <Col>
                        <TopTenCustomersAnalyticCard />
                    </Col>
                    <Col>
                        <PromotionCodeAnalyticCard />
                    </Col>
                </Col>
            </Grid>
        </>
    );
};

export default MainDashboard;

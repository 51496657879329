import { getCharities } from "@/services";
import { useQuery } from "react-query";

export const useGetCharitiesData = (enabled?: boolean) => {
    const {
        data: charities,
        isLoading: getCharitiesIsLoading,
        refetch: refetchGetCharities,
    } = useQuery("charities", getCharities, {
        enabled,
    });
    return { charities, getCharitiesIsLoading, refetchGetCharities };
};

import { axiosInstance } from "@/api/axios.instance";
import { ICampaign, IDraw, IPeriod, IUser } from "@/models";

export const getCampaigns = async (): Promise<ICampaign[]> =>
  await axiosInstance.get(`/campaigns`).then((res) => res.data);

export const getDrawData = async (campaignId: bigint | null): Promise<IDraw[]> =>
  await axiosInstance
    .get(`/campaigns/tickets/${campaignId ?? ''}`)
    .then((res) => res.data);

export const getDrawUserData = async (customerFBId: string): Promise<IUser> =>
  await axiosInstance
    .get(`/customer/details/${customerFBId}`)
    .then((res) => res.data);

export const getDrawTicketListData = async (campaignId: bigint | null): Promise<[]> =>
  await axiosInstance
    .get(`/campaigns/drawTicketList/${campaignId ?? ''}`)
    .then((res) => res.data);

export const addCampaign = async (campaign: Omit<ICampaign, "imageUrl">) =>
  await axiosInstance.post(`/campaigns`, campaign);

export const updateCampaign = async (campaign: Omit<ICampaign, "imageUrl">) =>
  await axiosInstance.put(`/campaigns`, campaign);

export const updateCampaignStatus = async (campaignId: bigint, status: string) =>
  await axiosInstance.put(`/campaigns/status`, null, {
    params: {
      id: campaignId,
      status: status,
    }
  });

export const deleteCampaign = async (campaignId: bigint) =>
  await axiosInstance.delete(`/campaigns/${campaignId}`);

export const deleteCampaignImage = async (
  campaignId: bigint | number,
): Promise<any> => await axiosInstance.delete(`/campaigns/image/${campaignId}`);

export const addCampaignImages = async (campaignImages: FormData) =>
  await axiosInstance.post(`/campaigns/image/upload`, campaignImages, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const addCampaignInfographImage = async (infographImage: FormData) =>
  await axiosInstance.post(`/campaigns/image/uploadInfoGraph`, infographImage, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const addCampaignInfographScndImage = async (infographScndImage: FormData) =>
  await axiosInstance.post(`/campaigns/image/uploadInfoGraphScnd`, infographScndImage, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
export const addCampaignPopupImages = async (campaignId: String | null, popupImages: FormData) =>
  await axiosInstance.post(`/campaigns/image/uploadDirectPopUpImages`, popupImages, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
    params: {
      campaignId: campaignId,
    }
  });

export const orderCampaigns = async (
  campaigns: Array<Omit<ICampaign, "imageUrl">>,
) => await axiosInstance.post(`/campaigns/order`, campaigns);

export const getDailyDrawTicketCount = async (drawId: string): Promise<any> =>
  await axiosInstance.get(`campaigns/ticketCountsDaily/${drawId}`)
    .then((res) => {
      const transformedData = res?.data?.map((item: any) => ({
        date: item[0],
        ticketCount: item[1],
      }))
      return transformedData;
    });

export const getDrawPeriods = async (campaignId: bigint): Promise<IPeriod[]> =>
  await axiosInstance.get(`/campaigns/period/get/${campaignId}`).then((res) => res.data);

export const addDrawPeriods = async (periods: IPeriod[]) =>
  await axiosInstance.post(`/campaigns/period/addList`, periods);

export const updateDrawPeriods = async (periods: IPeriod[]) =>
  await axiosInstance.put(`/campaigns/period/updateList`, periods);
import moment from "moment";
import React, { useState } from "react";
import { Column, Message } from "primereact";
import { DataTableCrud } from "@/components";
import { customerFilters } from "@/constants";
import { useTranslation } from "react-i18next";
import useGetConvertStepData from "@/hooks/analytic/useGetUserConvertStepData";

interface Props {
   customerId: number | null;
}

const CustomerConvertStepDataTable: React.FC<Props> = ({ customerId }) => {
   const [filters, setFilters] = useState(customerFilters);

   const { convertStepLog, getConvertStepLogDataLoading } =
      useGetConvertStepData(customerId);
   const { t } = useTranslation();

   return (
      <>
         <Message text={"Total earned SMP : " + convertStepLog?.totalConvertStepEarnedMoney} />
         <div style={{ padding: 10 }}> </div>

         <DataTableCrud
            value={convertStepLog?.convertStepLogs}
            loading={getConvertStepLogDataLoading}
            title="campaign logs"
            filters={filters}
         >
            <Column
               field="id"
               header={t("id")}
               sortable
               style={{ minWidth: "12rem" }}
            ></Column>
            <Column
               field="convertedStep"
               header={t("Converted Step")}
               sortable
               style={{ minWidth: "12rem" }}
            ></Column>
            <Column
               field="totalStep"
               header={t("Total Step")}
               sortable
               style={{ minWidth: "12rem" }}
            ></Column>
            <Column
               field="processDate"
               header={t("Date")}
               sortable
               style={{ minWidth: "12rem" }}
               body={(rowData) => <>{moment(rowData.processDate).format("DD-MM-YYYY")}</>}
            ></Column>

            <Column
               field="moneyAmount"
               header={t("SMP")}
               sortable
               style={{ minWidth: "12rem" }}
            ></Column>

         </DataTableCrud>
      </>
   );
};

export default CustomerConvertStepDataTable;

import { ICustomer } from "@/models";
import React, { useState } from "react";
import { customerFilters } from "@/constants";
import { Button, Column, Tooltip } from "primereact";
import { useGetCustomerInvitedFriendsData } from "@/hooks";
import {
    DataTableCrud,
    DataTableSearchHeader,
    CustomerAddressDialog,
} from "@/components";

interface Props {
    customerId: number | null;
}

const CustomerInvitedFriendsDataTable: React.FC<Props> = ({ customerId }) => {
    const [selectedRowData, setSelectedRowData] = useState<ICustomer | null>(null);

    const [filters, setFilters] = useState(customerFilters);

    const { invitedFriends, getInvitedFriendsIsLoading } = useGetCustomerInvitedFriendsData(customerId);

    const [invitedFriendDetailsDialog, setInvitedFriendDetailsDialog] = useState(false);

    const handleInfoButtonClick = (rowData: any) => {
        setSelectedRowData(rowData);
        setInvitedFriendDetailsDialog(true);
    };

    const hideInvitedFriendDetailsDialog = () => {
        setInvitedFriendDetailsDialog(false);
    };

    return (
        <>
            <DataTableCrud
                value={invitedFriends}
                loading={getInvitedFriendsIsLoading}
                title="invited friends"
                header={
                    <DataTableSearchHeader
                        title="User's Invited Friends"
                        filters={filters}
                        onChange={(e: any) => setFilters(e)}
                    />
                }
                filters={filters}
            >
                <Column
                    field="name"
                    header="Name"
                    sortable
                ></Column>
                <Column
                    field="surname"
                    header="Surname"
                    sortable
                ></Column>
                <Column
                    field="email"
                    header="Email"
                    sortable
                ></Column>
                <Column
                    field="moneyAmount"
                    header="SMP"
                    sortable
                ></Column>
                <Column
                    body={(rowData: ICustomer) => (
                        <>
                            <Tooltip target="#p-info-button" content="User Info" position="bottom" />
                            <Button
                                id="p-info-button"
                                icon="pi pi-info-circle"
                                className="p-button-rounded p-button-secondary m-1"
                                onClick={() => handleInfoButtonClick(rowData)}
                            />
                        </>
                    )}
                    exportable={false}
                    style={{ minWidth: "14rem" }}
                ></Column>
            </DataTableCrud>

            <CustomerAddressDialog
                visible={invitedFriendDetailsDialog}
                customerId={selectedRowData?.id ?? null}
                onHide={hideInvitedFriendDetailsDialog}
            />
        </>
    );
};

export default CustomerInvitedFriendsDataTable;

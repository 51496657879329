import moment from "moment";
import { useEffect, useState } from "react";
import { INotification } from "@/models";
import { useQueryClient } from "react-query";
import { notificationFilters } from "@/constants";
import { DataTableSearchHeader } from "@/components";
import { Badge, Column, DataTable } from "primereact";
import { useGetNotificationHistoryData } from "@/hooks";
import { Paginator, PaginatorPageState } from 'primereact/paginator';

const NotificationHistoryDataTable = () => {
    const queryClient = useQueryClient();
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(25);
    const [filters, setFilters] = useState(notificationFilters);

    const { notificationHistory, getNotificationHistoryIsLoading } = useGetNotificationHistoryData(page, rowsPerPage);
    const totalRecords = notificationHistory?.[0]?.totalElements ?? 0;

    useEffect(() => {
        queryClient.setQueryData(["notificationHistory", "page"], page);
        queryClient.setQueryData(["notificationHistory", "rowPerPage"], rowsPerPage);
    }, [page, rowsPerPage]);

    const onPageChange = (event: PaginatorPageState) => {
        setPage(event.page);
        setRowsPerPage(event.rows);
    };

    return (
        <>
            <DataTable
                dataKey="id"
                value={notificationHistory}
                loading={getNotificationHistoryIsLoading}
                title="notification history"
                header={
                    <DataTableSearchHeader
                        title="Notification History"
                        filters={filters}
                        onChange={(e: any) => setFilters(e)}
                    />
                }
                rows={rowsPerPage}
                responsiveLayout="scroll"
                filters={filters}
                filterDisplay="row"
                globalFilterFields={["msgCode", "title", "text"]}
                emptyMessage="Notification messages not found"
            >
                <Column
                    field="id"
                    header="Id"
                    sortable
                    style={{ minWidth: "4rem" }}
                ></Column>
                <Column
                    field="notificationType"
                    header="Type"
                    sortable
                    style={{ minWidth: "8rem" }}
                    body={(rowData: INotification) => {
                        return (
                            <Badge value={rowData?.notificationType} className={getBadgeColor(rowData?.notificationType)} />
                        );
                    }}
                ></Column>
                <Column
                    field="notificationMessage"
                    header="Message"
                    sortable
                    style={{ minWidth: "12rem" }}
                ></Column>
                <Column
                    field="date"
                    header="Date"
                    sortable
                    style={{ minWidth: "6rem" }}
                    body={(rowData: INotification) => (
                        <>{moment.utc(rowData.date).format("DD-MM-YYYY HH:mm")}</>
                    )}
                ></Column>
                <Column
                    field="sendedCustomerCount"
                    header="Sended User Count"
                    sortable
                    style={{ minWidth: "4rem" }}
                ></Column>
                <Column
                    field="successCount"
                    header="Success Count"
                    sortable
                    style={{ minWidth: "4rem" }}
                    body={(rowData: INotification) => (
                        <Badge value={rowData?.successCount ?? 0} className="p-badge-success" />
                    )}
                ></Column>
                <Column
                    field="failCount"
                    header="Fail Count"
                    sortable
                    style={{ minWidth: "4rem" }}
                    body={(rowData: INotification) => (
                        <Badge value={rowData?.failCount ?? 0} className="p-badge-danger" />
                    )}
                ></Column>
                <Column
                    field="clickedCount"
                    header="Clicked Count"
                    sortable
                    style={{ minWidth: "4rem" }}
                ></Column>
            </DataTable>
            <Paginator first={rowsPerPage * page} rows={rowsPerPage} totalRecords={totalRecords} rowsPerPageOptions={[25, 50, 100]} onPageChange={onPageChange} />
        </>
    );
};

export default NotificationHistoryDataTable;

function getBadgeColor(msgCode: string | null) {
    switch (msgCode) {
        case "NCS":
            return "p-badge-success";
        case "CMP_1000":
            return "p-badge-info";
        case "CMP_3000":
            return "p-badge-warning";
        case "CMP_5000":
            return "p-badge-danger";
        case "CMP_10000":
            return "p-badge-primary";
        case "CMP_20000":
            return "p-badge-secondary";
        case "ALL_CUSTOMERS":
            return "p-badge-success";
        case "APP_OPEN_REMINDER":
            return "p-badge-tertiary";
        case "SINGLE_NOTIFICATION":
            return "p-badge-quaternary";
        case "ORDER_SENT_NOTIFICATION":
            return "p-badge-info";
        default:
            return "p-badge-rose";
    }
}

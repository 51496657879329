import {
  Toast,
  Image,
  Button,
  Column,
  Tooltip,
  Dropdown,
  ColumnFilterElementTemplateOptions,
} from "primereact";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import {
  ImageDialog,
  DataTableCrud,
  DataTableToolbar,
  DataTableUpdateDialog,
  DataTableDeleteDialog,
  DataTableSearchHeader,
  DataTableActionTemplate,
  CharityDialog,
  CharitySortDialog,
  CharityShareImageUploadDialog,
  CharityInfoDialog,
} from "@/components";
import { ICharity } from "@/models";
import {
  initialCharity,
  charityFilters,
  charityStatusTypes,
} from "@/constants";
import {
  useGetCharityData,
  useAddCharityData,
  useGetCharitiesData,
  useUpdateCharityData,
  useDeleteCharityData,
  useOrderCharitiesData,
  useAddCharityImagesData,
  useDeleteCharityImageData,
  useUpdateCharityStatusData,
  useAddCharityShareImageData,
} from "@/hooks";
import NoImage from "@assets/images/no-image.png";
import { CHARITY_STATUS, DATA_TYPES } from "@/constants/enums";


const CharityDataTable = () => {
  const [selectedCharity, setSelectedCharity] = useState<ICharity | null>(null);
  const [filters, setFilters] = useState(charityFilters);
  const [charity, setCharity] = useState<ICharity>(initialCharity);
  const [deleteCharityDialog, setDeleteCharityDialog] = useState(false);
  const [updateCharityStatusDialog, setUpdateCharityStatusDialog] = useState(false);
  const [charityDialog, setCharityDialog] = useState(false);
  const [imageDialog, setImageDialog] = useState(false);
  const [shareImageDialog, setShareImageDialog] = useState(false);
  const [isAddItem, setIsAddItem] = useState(false);
  const [fileList, setFileList] = useState<File[]>([]);
  const [charitySortDialog, setCharitySortDialog] = useState(false);
  const [infoDialog, setInfoDialog] = useState(false);


  const formData = new FormData();

  const toast = useRef<Toast>(null);

  const {
    mutate: addCharityMutate,
    addCharityIsLoading,
    addCharityIsSuccess,
  } = useAddCharityData(toast);
  const {
    mutate: addCharityImages,
    addCharityImagesIsLoading,
    addCharityImagesIsSuccess,
  } = useAddCharityImagesData(toast);
  const {
    mutate: updateCharityMutate,
    updateCharityIsLoading,
    updateCharityIsSuccess,
  } = useUpdateCharityData(toast);
  const { mutate: deleteCharityMutate } = useDeleteCharityData(toast);
  const { mutate: updateCharityStatusMutate } = useUpdateCharityStatusData(toast);
  const { mutate: deleteCharityImagesMutate } = useDeleteCharityImageData(toast);
  const { charities, getCharitiesIsLoading } = useGetCharitiesData();
  const { mutate: orderCharitiesMutate } = useOrderCharitiesData(toast);
  const { mutate: addCharityShareImageMutate,
    addCharityShareImageIsLoading,
    addCharityShareImageIsSuccess
  } = useAddCharityShareImageData(toast);
  const { charity: charityDetail, getCharityIsLoading, refetchCharity } = useGetCharityData(charity.id);

  useEffect(() => {
    if (addCharityIsSuccess || updateCharityIsSuccess) {
      hideCharityDialog();
    }
  }, [addCharityIsSuccess, updateCharityIsSuccess]);

  useEffect(() => {
    if (addCharityImagesIsSuccess) {
      hideImageDialog();
    }
  }, [addCharityImagesIsSuccess]);

  useEffect(() => {
    if (addCharityShareImageIsSuccess) {
      hideShareImageDialog();
    }
  }, [addCharityShareImageIsSuccess]);

  const openDeleteCharityDialog = (charity?: ICharity) => {
    charity && setCharity(charity);
    setDeleteCharityDialog(true);
  };

  const openUpdateCharityStatusDialog = (charity?: ICharity) => {
    charity && setCharity(charity);
    setUpdateCharityStatusDialog(true);
  };

  const openAddCharityDialog = async () => {
    await setCharity(initialCharity);
    await setIsAddItem(true);
    setCharityDialog(true);
  };

  const openEditCharityDialog = async (rowData: ICharity) => {
    await setCharity(rowData);
    await setIsAddItem(false);
    setCharityDialog(true);
  };

  const openImageCharityDialog = async (rowData: ICharity) => {
    await setCharity({ ...rowData });
    setImageDialog(true);
    refetchCharity();
  };

  const openCharityShareImageDialog = async (rowData: ICharity) => {
    await setCharity({ ...rowData });
    setShareImageDialog(true);
    refetchCharity();
  };

  const openInfoDialog = async (rowData: ICharity) => {
    await setCharity({ ...rowData });
    setInfoDialog(true);
  };

  const hideCharityDialog = () => {
    setCharityDialog(false);
  };

  const hideImageDialog = () => {
    setImageDialog(false);
  };

  const hideShareImageDialog = () => {
    setShareImageDialog(false);
  };

  const hideDeleteCharityDialog = () => {
    setDeleteCharityDialog(false);
  };

  const hideUpdateCharityStatusDialog = () => {
    setUpdateCharityStatusDialog(false);
  };

  const hideInfoDialog = () => {
    setInfoDialog(false);
  };

  const saveCharity = (charity: Omit<ICharity, "imageUrl">) => {
    isAddItem ? addCharityMutate(charity) : updateCharityMutate(charity);
  };

  const saveImage = () => {
    formData.append("productId", charity.id?.toString() ?? "");
    fileList.forEach((file) => {
      formData.append("file", file);
    });
    addCharityImages(formData);
    refetchCharity();
  };

  const saveShareImage = (file: FileList) => {
    formData.append("productId", charity.id?.toString() ?? "");
    formData.append("file", file[0]);
    addCharityShareImageMutate({ charityShareImage: formData });
    refetchCharity();
  };

  const deleteCharity = () => {
    charity.id && deleteCharityMutate(charity.id);
    hideDeleteCharityDialog();
  };

  const updateCharityStatus = () => {
    charity.id && updateCharityStatusMutate({
      charityId: charity.id,
      status: charity.status === CHARITY_STATUS.ACTIVE ? CHARITY_STATUS.INACTIVE : CHARITY_STATUS.ACTIVE
    });
    hideUpdateCharityStatusDialog();
  };

  const deleteImageCharity = (id: bigint | number) => {
    deleteCharityImagesMutate(id);
    refetchCharity();
  };

  const imageBodyTemplate = (rowData: ICharity) => {
    return (
      <Image
        src={`${rowData.imageUrl}`}
        alt="charity"
        height="80"
        width="80"
        preview
        className="charity-image"
        onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
          (e.target as HTMLImageElement).src = NoImage;
        }}
      />
    );
  };

  const openCharitySortDialog = () => {
    setCharitySortDialog(true);
  };

  return (
    <>
      <Toast ref={toast} />

      <DataTableToolbar
        disableDeleteButton={!selectedCharity}
        openAddDialog={openAddCharityDialog}
        openDeleteDialog={openDeleteCharityDialog}
      >
        <Button
          id="p-sort-button"
          label="Sort"
          icon="pi pi-sort"
          className="p-button-primary mr-2"
          onClick={openCharitySortDialog}
        />
      </DataTableToolbar>

      <DataTableCrud
        value={charities}
        selection={selectedCharity}
        onSelectionChange={(e) => setSelectedCharity(e.value)}
        header={
          <DataTableSearchHeader
            title="Manage Charities"
            filters={filters}
            onChange={(e: any) => setFilters(e)}
          />
        }
        filters={filters}
        filterDisplay="row"
        globalFilterFields={["name", "type", "charityInstitutionName"]}
        loading={getCharitiesIsLoading}
        title="charities"
        emptyMessage="No charities found"
      >
        <Column
          field="name"
          header="Name"
          sortable
          style={{ minWidth: "12rem" }}
          filter
          filterPlaceholder="Search by name"
        ></Column>
        <Column field="image" header="Image" body={imageBodyTemplate}></Column>
        <Column
          field="charityDonationCount"
          header="Donation Count"
          sortable
        ></Column>
        <Column
          field="charityActualValue"
          header="Actual Value"
          sortable
        ></Column>
        <Column
          field="charityTargetValue"
          header="Target Value"
          sortable
        ></Column>
        <Column
          field="status"
          header="Status"
          sortable
          filter
          showFilterMenu={false}
          filterElement={(options: ColumnFilterElementTemplateOptions) => {
            return (
              <Dropdown
                value={options.value}
                options={charityStatusTypes}
                onChange={(e) => options.filterApplyCallback(e.value)}
                itemTemplate={(option: string) => {
                  return (
                    <span
                      className={`badge status-${option ? option.toLowerCase() : ""}`}
                    >
                      {option}
                    </span>
                  );
                }}
                valueTemplate={(option: string) => {
                  return (
                    <span
                      className={`badge status-${option ? option.toLowerCase() : ""}`}
                    >
                      {option}
                    </span>
                  );
                }}
                placeholder="Select a Status"
                className="p-column-filter"
              />
            );
          }}
          body={(rowData: ICharity) => (
            <Dropdown
              value={rowData.status}
              options={charityStatusTypes}
              onChange={(e) => {
                if (e.target.value && e.target.value !== CHARITY_STATUS.FINISHED) {
                  openUpdateCharityStatusDialog(rowData);
                }
              }}
              itemTemplate={(option: string) => {
                return (
                  <span
                    className={`badge status-${option ? option.toLowerCase() : ""}`}
                  >
                    {option}
                  </span>
                );
              }}
              valueTemplate={(option: string) => {
                return (
                  <span
                    className={`badge status-${option ? option.toLowerCase() : ""}`}
                  >
                    {option}
                  </span>
                );
              }}
              placeholder="Select a Status"
              style={{ minWidth: "10rem" }}
            />
          )}
        />
        <Column
          body={(rowData: ICharity) => (
            <div style={{ textAlign: "center" }}>
              <DataTableActionTemplate
                openDeleteDialog={() => openDeleteCharityDialog(rowData)}
                openEditDialog={async () => await openEditCharityDialog(rowData)}
              />
              <Tooltip target="#p-images-button" position="bottom" content="Images" />
              <Button
                id="p-images-button"
                icon="pi pi-images"
                className="p-button-rounded m-1"
                onClick={async () => await openImageCharityDialog(rowData)}
              />

              <Tooltip target="#p-share-image-button" content="Web Page Image" position="bottom" />
              <Button
                id="p-share-image-button"
                icon="pi pi-upload"
                className="p-button-rounded p-button-help m-1"
                onClick={async () => await openCharityShareImageDialog(rowData)}
              />

              <Tooltip target="#p-info-button" content="Info" position="bottom" />
              <Button
                id={"p-info-button"}
                icon="pi pi-info-circle"
                className="p-button-rounded p-button-secondary m-1"
                onClick={async () => await openInfoDialog(rowData)}
              />

            </div>
          )}
          exportable={false}
          style={{ width: "14rem" }}
        ></Column>
      </DataTableCrud>

      <DataTableDeleteDialog
        visible={deleteCharityDialog}
        data={selectedCharity ?? charity}
        onHide={hideDeleteCharityDialog}
        onDelete={deleteCharity}
      />

      <DataTableUpdateDialog
        visible={updateCharityStatusDialog}
        data={selectedCharity ?? charity}
        onHide={hideUpdateCharityStatusDialog}
        onUpdate={updateCharityStatus}
        text={`Are you sure you want to change status to ${charity.status === CHARITY_STATUS.ACTIVE ? CHARITY_STATUS.INACTIVE : CHARITY_STATUS.ACTIVE}?`}
      />

      <CharityDialog
        visible={charityDialog}
        charity={charity}
        isLoading={isAddItem ? addCharityIsLoading : updateCharityIsLoading}
        isAddItem={isAddItem}
        onHide={hideCharityDialog}
        addCharity={saveCharity}
      />

      <ImageDialog
        visible={imageDialog}
        onHide={hideImageDialog}
        addImage={saveImage}
        fileList={fileList}
        setFileList={setFileList}
        isLoading={getCharityIsLoading}
        isLoadingFooter={addCharityImagesIsLoading}
        data={charityDetail}
        dataType={DATA_TYPES.CHARITY}
        deleteImage={deleteImageCharity}
      />

      <CharitySortDialog
        visible={charitySortDialog}
        onHide={() => setCharitySortDialog(false)}
        isLoading={updateCharityIsLoading}
        orderCharities={orderCharitiesMutate}
        charities={
          charities
            ?.filter((charity) => charity.status === CHARITY_STATUS.ACTIVE)
            .sort((a, b) => a.sequence - b.sequence) ?? []
        }
      />

      <CharityShareImageUploadDialog
        rowData={charity}
        visible={shareImageDialog}
        onHide={hideShareImageDialog}
        saveImage={saveShareImage}
        isLoading={addCharityShareImageIsLoading}
      />

      <CharityInfoDialog
        visible={infoDialog}
        onHide={hideInfoDialog}
        charity={charity}
      />
    </>
  );
};

export default CharityDataTable;

import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

interface ImageUploadResultDialogProps {
    visible: boolean;
    imageUrl: string;
    onHide: () => void;
}

const ImageUploadResultDialog = ({ visible, imageUrl, onHide }: ImageUploadResultDialogProps) => {
    const copyToClipboard = () => {
        navigator.clipboard.writeText(imageUrl);
        alert('URL copied to clipboard!');
    };

    return (
        <Dialog
            header="Image Upload Successful"
            visible={visible}
            style={{ width: '30vw' }}
            onHide={onHide}
            footer={
                <div>
                    <Button label="Copy URL" icon="pi pi-copy" onClick={copyToClipboard} className="p-button-success" />
                    <Button label="Close" icon="pi pi-times" onClick={onHide} className="p-button-secondary" />
                </div>
            }
        >
            <p>Here is your uploaded image URL:</p>
            <InputText value={imageUrl} readOnly style={{ width: '100%' }} />
        </Dialog>
    );
};

export default ImageUploadResultDialog;
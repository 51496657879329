import React, { useEffect } from "react";
import {
  DataTableDialogFooter,
  DropdownField,
  ErrorField,
  InputField,
  InputNumberField,
} from "@/components";
import { Dialog } from "primereact";
import { IStock, ISupplier } from "@/models";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { capitalizeFirstLetter, isFormFieldValid } from "@/utils";
import classNames from "classnames";
import { useGetProfileInfoData } from "@/hooks/profile/useGetProfileInfoData";

interface ProductStockDialogProps {
  visible: boolean;
  onHide: () => void;
  suppliers: ISupplier[] | undefined;
  isLoading: boolean;
  stock: IStock;
  addStock: (stock: IStock) => void;
}

const ProductStockDialog: React.FC<ProductStockDialogProps> = ({
  visible,
  onHide,
  suppliers,
  isLoading,
  stock,
  addStock,
}) => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: stock,
    enableReinitialize: true,
    onSubmit: (data) => {
      addStock(data);
    },
  });

  const supplierOptions = suppliers?.map((supplier) => ({
    label: supplier.name,
    value: supplier,
  }));

  //  const [taxes, setTaxes] = React.useState<number>(0);
  const [unitPrice, setUnitPrice] = React.useState<number>(0);
  const [amount, setAmount] = React.useState<number>(0);
  const { profileInfo } = useGetProfileInfoData();

  const hideDialog = () => {
    onHide();
    //   setTaxes(0);
    formik.resetForm();
  };

  useEffect(() => {
    formik.setFieldValue("total", unitPrice * amount);
    //  }, [taxes, unitPrice, amount]);
  }, [unitPrice, amount]);


  return (
    <Dialog
      visible={visible}
      style={{ width: "450px" }}
      header={t("Add Product To Stock")}
      modal
      className="p-fluid"
      footer={
        <DataTableDialogFooter
          hideDialog={hideDialog}
          handleSubmit={formik.handleSubmit}
          loading={isLoading}
        />
      }
      onHide={hideDialog}
    >
      <form onSubmit={formik.handleSubmit}>
        <InputField
          id="name"
          disabled
          value={formik.values.product.name ?? ""}
          type="text"
          required
          onChange={formik.handleChange}
          labelName="name"
          labelText={capitalizeFirstLetter("name")}
          getFormErrorMessage={() => <ErrorField formik={formik} name="name" />}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "name"),
          })}
        />
        <InputField
          id="billNo"
          value={formik.values.billNo ?? ""}
          type="text"
          required
          autoFocus={true}
          onChange={formik.handleChange}
          labelName="billNo"
          labelText={capitalizeFirstLetter("Bill No")}
          getFormErrorMessage={() => <ErrorField formik={formik} name="billNo" />}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "billNo"),
          })}
        />
        <InputNumberField
          id="unitPrice"
          inputId="unitPrice"
          type="text"
          mode="currency"
          currency={
            (localStorage.getItem('countryCode') ?? profileInfo?.countryCode ?? '') === "ID" ? "IDR" :
              (localStorage.getItem('countryCode') ?? profileInfo?.countryCode ?? '') === "PH" ? "PHP" :
                (localStorage.getItem('countryCode') ?? profileInfo?.countryCode ?? '') === "UK" ? "GBP" :
                  "USD" // Default currency if no matching condition is met
          }
          showButtons
          value={formik.values.unitPrice ?? ""}
          required
          onChange={(e) => setUnitPrice(e.value ?? 0)}
          min={0}
          onValueChange={formik.handleChange}
          labelName="unitPrice"
          labelText={capitalizeFirstLetter("Unit Price")}
          getFormErrorMessage={() => <ErrorField formik={formik} name="unitPrice" />}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "unitPrice"),
          })}
        />
        <InputNumberField
          id="amount"
          inputId="amount"
          type="text"
          showButtons
          value={formik.values.amount ?? ""}
          required
          onChange={(e) => setAmount(e.value ?? 0)}
          onValueChange={formik.handleChange}
          labelName="quantity"
          min={0}
          labelText={capitalizeFirstLetter("Quantity")}
          getFormErrorMessage={() => <ErrorField formik={formik} name="amount" />}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "amount"),
          })}
        />
        {/* <InputNumberField
     id="Taxes"
     inputId="Taxes"
     showButtons
     mode="decimal"
     minFractionDigits={2}
     value={taxes}
     required
     onChange={(e) => setTaxes(e.value ?? 0)}
     labelName="Taxes"
     labelText={capitalizeFirstLetter("Taxes")}
     getFormErrorMessage={() => <ErrorField formik={formik} name="Taxes" />}
     className={classNames({
      "p-invalid": isFormFieldValid(formik, "Taxes"),
     })}
    /> */}
        <DropdownField
          id="supplier"
          name="supplier"
          value={formik.values.supplier}
          onChange={formik.handleChange}
          options={supplierOptions ?? []}
          filter={true}
          labelName={"supplier"}
          labelText={capitalizeFirstLetter("supplier")}
          getFormErrorMessage={() => (
            <ErrorField formik={formik} name={"supplier"} />
          )}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "supplier"),
          })}
        />
        <InputNumberField
          id="total"
          inputId="total"
          disabled
          mode="currency"
          currency={
            (localStorage.getItem('countryCode') ?? profileInfo?.countryCode ?? '') === "ID" ? "IDR" :
              (localStorage.getItem('countryCode') ?? profileInfo?.countryCode ?? '') === "PH" ? "PHP" :
                (localStorage.getItem('countryCode') ?? profileInfo?.countryCode ?? '') === "UK" ? "GBP" :
                  "USD" // Default currency if no matching condition is met
          }
          value={formik.values.total ?? ""}
          required
          onValueChange={formik.handleChange}
          labelName="total"
          labelText={capitalizeFirstLetter("total")}
          getFormErrorMessage={() => <ErrorField formik={formik} name="total" />}
          className={classNames({
            "p-invalid": isFormFieldValid(formik, "total"),
          })}
        />
      </form>
    </Dialog>
  );
};

export default ProductStockDialog;

import { getDonorData } from "@/services";
import { useQuery } from "react-query";

export const useGetDonorData = (charityId: number | null) => {
    const {
        data: donor,
        isLoading: getDonorDataIsLoading,
    } = useQuery(
        ["donors", charityId],
        async () => await getDonorData(charityId),
    );
    return { donor, getDonorDataIsLoading };
};

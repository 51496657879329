import {
  Toast,
  Image,
  Button,
  Column,
  Tooltip,
  Dropdown,
  InputNumber,
  ColumnFilterElementTemplateOptions,
} from "primereact";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import {
  ImageDialog,
  ProductDialog,
  DataTableCrud,
  DataTableToolbar,
  ProductSortDialog,
  ProductStockDialog,
  DataTableUpdateDialog,
  DataTableDeleteDialog,
  DataTableSearchHeader,
  DataTableActionTemplate,
} from "@/components";
import { IProduct, IStock } from "@/models";
import {
  STATUS,
  initialStock,
  productFilters,
  statusTypes,
  initialProduct,
} from "@/constants";
import {
  useAddStockData,
  useAddProductData,
  useGetProductData,
  useGetProductsData,
  useGetSuppliersData,
  useDeleteProductData,
  useUpdateProductData,
  useUpdateProductStatusData,
  useGetCategoriesData,
  useAddProductImagesData,
  useDeleteProductImageData,
} from "@/hooks";
import NoImage from "@assets/images/no-image.png";
import { useTranslation } from "react-i18next";
import { DATA_TYPES } from "@/constants/enums";
import { useOrderProductsData } from "@/hooks/product/useOrderProductsData";


const ProductDataTable = () => {
  const [selectedProduct, setSelectedProduct] = useState<Omit<
    IProduct,
    "imageUrl"
  > | null>(null);
  const [filters, setFilters] = useState(productFilters);
  const [product, setProduct] =
    useState<Omit<IProduct, "imageUrl">>(initialProduct);
  const [stock, setStock] = useState<IStock>(initialStock);
  const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  const [updateProductStatusDialog, setUpdateProductStatusDialog] = useState(false);
  const [productDialog, setProductDialog] = useState(false);
  const [imageDialog, setImageDialog] = useState(false);
  const [productStockDialog, setProductStockDialog] = useState(false);
  const [isAddItem, setIsAddItem] = useState(false);
  const [fileList, setFileList] = useState<File[]>([]);
  const [productSortDialog, setProductSortDialog] = useState(false);

  const formData = new FormData();

  const toast = useRef<Toast>(null);

  const {
    mutate: addProductMutate,
    addProductIsLoading,
    addProductIsSuccess,
  } = useAddProductData(toast);
  const {
    mutate: addProductImages,
    addProductImagesIsLoading,
    addProductImagesIsSuccess,
  } = useAddProductImagesData(toast);
  const {
    mutate: addStockMutate,
    addStockIsLoading,
    addStockIsSuccess,
  } = useAddStockData(toast);
  const {
    mutate: updateProductMutate,
    updateProductIsLoading,
    updateProductIsSuccess,
  } = useUpdateProductData(toast);
  const { mutate: deleteProductMutate } = useDeleteProductData(toast);
  const { mutate: updateProductStatusMutate } = useUpdateProductStatusData(toast);
  const { mutate: deleteProductImagesMutate } = useDeleteProductImageData(toast);
  const { products, getProductsIsLoading } = useGetProductsData();
  const { mutate: orderProductMutate } = useOrderProductsData(toast);
  const {
    product: productDetail,
    getProductIsLoading,
    refetchProduct,
  } = useGetProductData(product.id);
  const { suppliers, getSuppliersRefetch } = useGetSuppliersData({
    enabled: false,
  });
  const { categories, getRefetchCategories } = useGetCategoriesData({
    enabled: false,
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (addProductIsSuccess || updateProductIsSuccess) {
      hideProductDialog();
    }
  }, [addProductIsSuccess, updateProductIsSuccess]);

  useEffect(() => {
    if (addStockIsSuccess) {
      hideProductStockDialog();
    }
  }, [addStockIsSuccess]);

  useEffect(() => {
    if (addProductImagesIsSuccess) {
      hideImageDialog();
    }
  }, [addProductImagesIsSuccess]);

  const openDeleteProductDialog = (product?: IProduct) => {
    product && setProduct(product);
    setDeleteProductDialog(true);
  };

  const openUpdateProductStatusDialog = (product?: IProduct) => {
    product && setProduct(product);
    setUpdateProductStatusDialog(true);
  };

  const openAddProductDialog = async () => {
    await setProduct(initialProduct);
    await setIsAddItem(true);
    await getRefetchCategories();
    setProductDialog(true);
  };

  const openEditProductDialog = async (rowData: IProduct) => {
    const { imageUrl, ...rest } = rowData;
    await setProduct({ ...rest });
    await setIsAddItem(false);
    await getRefetchCategories();
    setProductDialog(true);
  };

  const openImageProductDialog = async (rowData: IProduct) => {
    await setProduct({ ...rowData });
    setImageDialog(true);
    refetchProduct();
  };

  const openStockProductDialog = async (rowData: IProduct) => {
    await setStock({ ...stock, product: { ...rowData } });
    await getSuppliersRefetch();
    setProductStockDialog(true);
  };

  const hideProductDialog = () => {
    setProductDialog(false);
  };

  const hideImageDialog = () => {
    setImageDialog(false);
  };

  const hideProductStockDialog = () => {
    setProductStockDialog(false);
  };

  const hideDeleteProductDialog = () => {
    setDeleteProductDialog(false);
  };

  const hideUpdateProductStatusDialog = () => {
    setUpdateProductStatusDialog(false);
  };

  const saveProduct = (product: Omit<IProduct, "imageUrl">) => {
    isAddItem ? addProductMutate(product) : updateProductMutate(product);
  };

  const saveImage = () => {
    formData.append("productId", product.id?.toString() ?? "");
    fileList.forEach((file) => {
      formData.append("file", file);
    });
    addProductImages(formData);
    refetchProduct();
  };

  const deleteProduct = () => {
    product.id && deleteProductMutate(product.id);
    hideDeleteProductDialog();
  };

  const updateProductStatus = () => {
    product.id && updateProductStatusMutate({
      productId: product.id,
      status: product.status === STATUS.ACTIVE ? STATUS.INACTIVE : STATUS.ACTIVE
    });
    hideUpdateProductStatusDialog();
  };

  const deleteImageProduct = (id: bigint | number) => {
    deleteProductImagesMutate(id);
    refetchProduct();
  };

  const saveStock = (stock: IStock) => {
    addStockMutate(stock);
  };

  const imageBodyTemplate = (rowData: IProduct) => {
    return (
      <Image
        src={`${rowData.imageUrl}`}
        alt="product"
        height="80"
        width="80"
        preview
        className="product-image"
        onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
          (e.target as HTMLImageElement).src = NoImage;
        }}
      />
    );
  };

  const openProductSortDialog = () => {
    setProductSortDialog(true);
  };

  return (
    <>
      <Toast ref={toast} />

      <DataTableToolbar
        disableDeleteButton={!selectedProduct}
        openAddDialog={openAddProductDialog}
        openDeleteDialog={openDeleteProductDialog}
      >
        <Button
          id="p-sort-button"
          label="Sort"
          icon="pi pi-sort"
          className="p-button-primary mr-2"
          onClick={openProductSortDialog}
        />
      </DataTableToolbar>

      <DataTableCrud
        value={products}
        selection={selectedProduct}
        onSelectionChange={(e) => setSelectedProduct(e.value)}
        header={
          <DataTableSearchHeader
            title={t("Manage Products")}
            filters={filters}
            onChange={(e: any) => setFilters(e)}
          />
        }
        filters={filters}
        filterDisplay="row"
        globalFilterFields={["name", "type", "sellingPoint", "stockQuantity"]}
        loading={getProductsIsLoading}
        title="products"
        emptyMessage={t("No products found")}
      >
        <Column
          field="name"
          header={t("Name")}
          sortable
          style={{ minWidth: "12rem" }}
          filter
          filterPlaceholder="Search by name"
        ></Column>
        <Column field="image" header="Image" body={imageBodyTemplate}></Column>
        <Column
          field="type"
          header={t("Type")}
          sortable
        ></Column>
        <Column
          field="sellingPoint"
          header={t("Price")}
          sortable
        ></Column>
        <Column
          field="stockQuantity"
          header={t("Stock Quantity")}
          sortable
        ></Column>
        <Column
          field="status"
          header="Status"
          sortable
          filter
          showFilterMenu={false}
          filterElement={(options: ColumnFilterElementTemplateOptions) => {
            return (
              <Dropdown
                value={options.value}
                options={statusTypes}
                onChange={(e) => options.filterApplyCallback(e.value)}
                itemTemplate={(option: string) => {
                  return (
                    <span
                      className={`badge status-${option ? option.toLowerCase() : ""}`}
                    >
                      {option}
                    </span>
                  );
                }}
                valueTemplate={(option: string) => {
                  return (
                    <span
                      className={`badge status-${option ? option.toLowerCase() : ""}`}
                    >
                      {option}
                    </span>
                  );
                }}
                placeholder="Select a Status"
                className="p-column-filter"
              />
            );
          }}
          body={(rowData: IProduct) => (
            <Dropdown
              value={rowData.status}
              options={statusTypes}
              onChange={(e) => {
                e.target.value && openUpdateProductStatusDialog(rowData);
              }}
              itemTemplate={(option: string) => {
                return (
                  <span
                    className={`badge status-${option ? option.toLowerCase() : ""}`}
                  >
                    {option}
                  </span>
                );
              }}
              valueTemplate={(option: string) => {
                return (
                  <span
                    className={`badge status-${option ? option.toLowerCase() : ""}`}
                  >
                    {option}
                  </span>
                );
              }}
              placeholder="Select a Status"
              style={{ minWidth: "10rem" }}
            />
          )}
        />
        <Column
          body={(rowData: IProduct) => (
            <>
              <DataTableActionTemplate
                openDeleteDialog={() => openDeleteProductDialog(rowData)}
                openEditDialog={async () => await openEditProductDialog(rowData)}
              />
              <Tooltip target="#p-images-button" position="bottom" content="Images" />
              <Tooltip
                target="#p-stock-button"
                position="bottom"
                content="Add Product Stock"
              />
              <Button
                id="p-images-button"
                icon="pi pi-images"
                className="p-button-rounded m-1"
                onClick={async () => await openImageProductDialog(rowData)}
              />

              <Button
                id="p-stock-button"
                icon="pi pi-shopping-cart"
                className="p-button-rounded p-button-danger m-1"
                onClick={async () => await openStockProductDialog(rowData)}
              />
            </>
          )}
          exportable={false}
          style={{ minWidth: "16rem" }}
        ></Column>
      </DataTableCrud>

      <DataTableDeleteDialog
        visible={deleteProductDialog}
        data={selectedProduct ?? product}
        onHide={hideDeleteProductDialog}
        onDelete={deleteProduct}
      />

      <DataTableUpdateDialog
        visible={updateProductStatusDialog}
        data={selectedProduct ?? product}
        onHide={hideUpdateProductStatusDialog}
        onUpdate={updateProductStatus}
        text={`Are you sure you want to change status to ${product.status === STATUS.ACTIVE ? STATUS.INACTIVE : STATUS.ACTIVE}?`}
      />

      <ProductDialog
        visible={productDialog}
        product={product}
        categories={categories}
        isLoading={isAddItem ? addProductIsLoading : updateProductIsLoading}
        isAddItem={isAddItem}
        onHide={hideProductDialog}
        addProduct={saveProduct}
      />

      <ImageDialog
        visible={imageDialog}
        onHide={hideImageDialog}
        addImage={saveImage}
        fileList={fileList}
        setFileList={setFileList}
        isLoading={getProductIsLoading}
        isLoadingFooter={addProductImagesIsLoading}
        data={productDetail}
        dataType={DATA_TYPES.PRODUCT}
        deleteImage={deleteImageProduct}
      />

      <ProductStockDialog
        visible={productStockDialog}
        onHide={hideProductStockDialog}
        suppliers={suppliers}
        isLoading={addStockIsLoading}
        stock={stock}
        addStock={saveStock}
      />

      <ProductSortDialog
        visible={productSortDialog}
        onHide={() => setProductSortDialog(false)}
        isLoading={updateProductIsLoading}
        orderProducts={orderProductMutate}
        products={products?.sort((a, b) => a.sequence - b.sequence) ?? []}
      />
    </>
  );
};

export default ProductDataTable;

import React, { createContext, useState, useContext, useEffect } from 'react';

interface CountryCodeContextProps {
    countryCode: string | null;
    setCountryCode: (code: string) => void;
}

const CountryCodeContext = createContext<CountryCodeContextProps | undefined>(undefined);

export const CountryCodeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [countryCode, setCountryCode] = useState<string | null>(localStorage.getItem('countryCode'));

    useEffect(() => {
        if (countryCode) {
            localStorage.setItem('countryCode', countryCode);
        }
    }, [countryCode]);

    return (
        <CountryCodeContext.Provider value={{ countryCode, setCountryCode }}>
            {children}
        </CountryCodeContext.Provider>
    );
};

export const useCountryCode = () => {
    const context = useContext(CountryCodeContext);
    if (!context) {
        throw new Error('useCountryCode must be used within a CountryCodeProvider');
    }
    return context;
};
import {
    CustomerAdsLogDataTable,
    CustomerDetailDataTable,
    CustomerCkpHistoryChart,
    CustomerOrdersLogDataTable,
    CustomerCampaignLogDataTable,
    CustomerConvertStepDataTable,
} from "@/components";

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, TabPanel, TabView } from "primereact";

interface CustomerDialogProps {
    visible: boolean;
    customerId: number | null;
    onHide: () => void;
}

const CustomerAddressDialog: React.FC<CustomerDialogProps> = ({
    visible,
    onHide,
    customerId,
}) => {
    const { t } = useTranslation();

    const hideDialog = () => {
        onHide();
    };

    const [activeIndex, setActiveIndex] = React.useState(0);

    useEffect(() => {
        if (visible) {
            setActiveIndex(0); // Set the active tab to the first tab when the dialog is shown
        }
    }, [visible]);

    return (
        <Dialog
            visible={visible}
            style={{ width: "70%" }}
            header={t("User Info Details")}
            modal
            className="p-fluid"
            onHide={hideDialog}
        >
            <TabView
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
            >
                <TabPanel header="Details">
                    <div className="datatable-crud">
                        <div className="card">
                            <CustomerDetailDataTable customerId={customerId ?? null} />
                        </div>
                    </div>
                </TabPanel>

                {/* <TabPanel header="Addresses">

    </TabPanel> */}

                <TabPanel header="SMP History">
                    <CustomerCkpHistoryChart customerId={customerId ?? null} />
                </TabPanel>

                <TabPanel header="Ad Records">
                    <div className="datatable-crud">
                        <div className="card">
                            <CustomerAdsLogDataTable customerId={customerId ?? null} />
                        </div>
                    </div>
                </TabPanel>

                <TabPanel header="Campaign Use Records">
                    <div className="datatable-crud">
                        <div className="card">
                            <CustomerCampaignLogDataTable customerId={customerId ?? null} />
                        </div>
                    </div>
                </TabPanel>

                <TabPanel header="Convert Step Records">
                    <div className="datatable-crud">
                        <div className="card">
                            <CustomerConvertStepDataTable customerId={customerId ?? null} />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel header="Order Records">
                    <div className="datatable-crud">
                        <div className="card">
                            <CustomerOrdersLogDataTable customerId={customerId ?? null} />
                        </div>
                    </div>
                </TabPanel>
            </TabView>
        </Dialog>
    );
};

export default CustomerAddressDialog;

import { axiosInstance } from "@/api/axios.instance";

export const getAdViewingRate = async (): Promise<number | null> =>
    await axiosInstance
        .get(`/reporting/adView/rateLastWatch`)
        .then((res) => res.data);

export const getUserAdInfoRate = async (): Promise<[]> =>
    await axiosInstance
        .get(`/reporting/adView/rateUserAdInfo`)
        .then((res) => res.data);

export const getAdsPerHourInLast24Hours = async (): Promise<any> =>
    await axiosInstance
        .get(`reporting/adView/lastDay`)
        .then((res) => res.data.slice().reverse());

export const getApuAndAdWatchedUserCountInLast7Days = async (): Promise<any> =>
    await axiosInstance
        .get(`reporting/adView/lastWeek`)
        .then((res) => res.data);

export const getRetentionInLast7Days = async (): Promise<any> =>
    await axiosInstance
        .get(`reporting/retention/lastWeek`)
        .then((res) => res.data);

export const getCustomerCountByStep = async (date: string): Promise<any> =>
    await axiosInstance
        .get(`customer/stepDistribution`, { params: { date: date } })
        .then((res) => res.data);
import { ICharity, IDonor } from "@/models";
import { axiosInstance } from "@/api/axios.instance";
import { QueryFunctionContext } from "react-query";

export const getCharities = async (): Promise<ICharity[]> =>
  await axiosInstance.get(`/product/charity`).then((res) => res.data);

export const getCharity = async ({
  queryKey,
}: QueryFunctionContext<[string, number | null]>): Promise<ICharity> => {
  const [, charityId] = queryKey;
  return await axiosInstance
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    .get(`/product/${charityId}`)
    .then((res) => res.data);
};

export const getCharityDetail = async (charityId: bigint | number) => {
  return await axiosInstance
    .get(`/product/${charityId}`)
    .then((res) => res.data);
};

export const addCharity = async (charity: Omit<ICharity, "imageUrl">) =>
  await axiosInstance.post(`/product`, charity);

export const addCharityImages = async (charityImages: FormData) =>
  await axiosInstance.post(`/product/images/upload`, charityImages, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const addCharityShareImage = async (charityShareImage: FormData) =>
  await axiosInstance.post(`/product/images/uploadCharityShareImage`, charityShareImage, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

export const deleteCharityImage = async (
  imageId: bigint | number,
): Promise<any> =>
  await axiosInstance.delete(`/product/images/delete/${imageId}`);

export const updateCharity = async (charity: Omit<ICharity, "imageUrl">) =>
  await axiosInstance.put(`/product`, charity);

export const updateCharityStatus = async (charityId: number, status: string) =>
  await axiosInstance.put(`/product/status`, null, {
    params: {
      id: charityId,
      status: status,
    }
  });

export const deleteCharity = async (charityId: number) =>
  await axiosInstance.delete(`/product/${charityId}`);

export const orderCharities = async (charities: Array<Omit<ICharity, "imageUrl">>) =>
  await axiosInstance.post(`/product/updateOrderAndStatus`, charities);

export const getDonorData = async (charityId: number | null): Promise<IDonor[]> =>
  await axiosInstance
    .get(`/product/donationList`, {
      params: {
        productId: charityId,
        page: 0,
        limit: 10000,
      }
    })
    .then((res) => res.data.donationLogList);

import { ICharity } from "@/models";
import { DonorDataTable } from "@/components";

interface DonorProps {
    charity: ICharity | null;
}

const Donor = ({ charity }: DonorProps) => {
    return (
        <div className="datatable-crud">
            <div className="card" style={{ padding: 0 }}>
                <DonorDataTable charityId={charity?.id ?? null} />
            </div>
        </div>
    );
};

export default Donor;

import moment from "moment";
import { DataTableToolbar } from "../../common";
import { CONFIG_VARS, ROLE } from "@/constants";
import { INotificationMessage } from "@/models";
import { AddressDataTable } from "../../address";
import { CustomerAddCkpDialog } from "@/components/Dialog";
import React, { useEffect, useRef, useState } from "react";
import { Button, Dialog, ProgressSpinner, Toast, Tooltip } from "primereact";
import { useGetCustomerBasicData, useRole, useTheme } from "@/hooks";
import { useAddCkpToCustomer } from "@/hooks/customer/useAddCkpToCustomer";
import { CustomerDetailedInfoDataTable, CustomerInvitedFriendsDataTable } from "@/components";

interface CustomerDialogProps {
  customerId: number | null;
}

const CustomerDetailDataTable: React.FC<CustomerDialogProps> = ({
  customerId,
}) => {
  const { role } = useRole();
  const { layoutColorMode } = useTheme();

  const [addCkpDialog, setAddCkpDialog] = useState(false);

  const [addressDialog, setAddressDialog] = useState(false);

  const [detailedInfoDialog, setDetailedInfoDialog] = useState(false);

  const [invitedFriendsDialog, setInvitedFriendsDialog] = useState(false);

  const { customerBasic, getCustomerBasicIsLoading } = useGetCustomerBasicData(customerId ?? 0);

  const toast = useRef<Toast>(null);

  const {
    mutate: addCkpMutate,
    addCkpIsLoading,
    addCkpIsSuccess,
  } = useAddCkpToCustomer(toast);

  useEffect(() => {
    if (addCkpIsSuccess) {
      hideAddCkpDialog();
    }
  }, [addCkpIsSuccess]);

  const openAddCkpDialog = async () => {
    setAddCkpDialog(true);
  };

  const hideAddCkpDialog = () => {
    setAddCkpDialog(false);
  };

  const saveNotificationMessage = (notificationMessage: INotificationMessage) => {
    if (customerId && notificationMessage) {
      addCkpMutate({ customerId: customerId, notificationMessage: notificationMessage });
    }
  };

  const openAddressDialog = async () => {
    setAddressDialog(true);
  };

  const hideAddressDialog = () => {
    setAddressDialog(false);
  };

  const openInvitedFriendsDialog = async () => {
    setInvitedFriendsDialog(true);
  };

  const hideInvitedFriendsDialog = () => {
    setInvitedFriendsDialog(false);
  };

  const openDetailedInfoDialog = async () => {
    setDetailedInfoDialog(true);
  };

  const hideDetailedInfoDialog = () => {
    setDetailedInfoDialog(false);
  };

  const getColor = (percentage: number): string => {
    if (percentage >= 70) {
      return 'active';
    } else if (percentage >= 40) {
      return 'preparing';
    } else {
      return 'deleted';
    }
  };


  return (
    <>
      <Toast ref={toast} />
      {role === ROLE.ADMIN && (
        <DataTableToolbar>
          <Button
            label="Add SMP"
            id="p-send-notification-button"
            icon="pi pi-plus"
            className="p-button-success mr-2"
            onClick={openAddCkpDialog}
          /></DataTableToolbar>)}

      {(customerBasic) ? (
        <table
          style={{
            border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
              ? "rgba(0, 0, 0, .05)"
              : "rgba(255, 255, 255, 0.6)"
              }`,
            borderCollapse: "collapse",
            textAlign: "left",
            width: "100%",
          }}
        >
          <tbody>

            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                Detailed Info:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                <Tooltip target="#p-detailed-info-button" content="View Detailed Info" position="bottom" />
                <Button
                  id="p-detailed-info-button"
                  icon="pi pi-info-circle"
                  className="p-button p-button-info m-0"
                  style={{ height: 30, width: 50 }}
                  onClick={openDetailedInfoDialog}
                />
              </td>
            </tr>

            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                Name:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                {customerBasic.name?.toString()}
              </td>
            </tr>

            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                Surname:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                {customerBasic.surname?.toString()}
              </td>
            </tr>

            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                Email:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                {customerBasic.email?.toString()}
              </td>
            </tr>

            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                Gsm No:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                {customerBasic.gsmno?.toString()}
              </td>
            </tr>

            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                Birth Date:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                {customerBasic.birthDate
                  ? moment(customerBasic.birthDate).format("DD-MM-YYYY").toString()
                  : ""}
              </td>
            </tr>

            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                Gender:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                {customerBasic.gender?.toString()}
              </td>
            </tr>

            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                City:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                {customerBasic.city?.toString()}
              </td>
            </tr>

            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                SMP:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                {customerBasic.moneyAmount?.toString()}
              </td>
            </tr>

            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                Total Step:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                {customerBasic.totalStep?.toString()}
              </td>
            </tr>

            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                Average Step:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                {customerBasic.averageStep?.toString()}
              </td>
            </tr>

            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                Registration Date:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                {customerBasic.createDate
                  ? moment(customerBasic.createDate).format("DD-MM-YYYY").toString()
                  : null}
              </td>
            </tr>
            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                Process Date:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                {customerBasic.processDate
                  ? moment(customerBasic.processDate).format("DD-MM-YYYY").toString()
                  : null}
              </td>
            </tr>

            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                Status:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                <span
                  className={`badge status-${customerBasic.status ? customerBasic.status.toLowerCase() : ""
                    }`}
                >
                  {customerBasic.status?.toString()}
                </span>
              </td>
            </tr>

            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                Adresses:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                <Tooltip target="#p-addresses-button" content="View Addresses" position="bottom" />
                <Button
                  id="p-addresses-button"
                  icon="pi pi-map-marker"
                  className="p-button p-button-primary m-0"
                  style={{ height: 30, width: 50 }}
                  onClick={openAddressDialog}
                />
              </td>
            </tr>

            <tr>
              <th
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                Invited Friends:
              </th>
              <td
                style={{
                  border: `1px solid ${layoutColorMode === CONFIG_VARS.LIGHT
                    ? "rgba(0, 0, 0, .05)"
                    : "rgba(255, 255, 255, 0.6)"
                    }`,

                  padding: "8px",
                }}
              >
                <Tooltip target="#p-invited-friends-button" content="View Invited Friends" position="bottom" />
                <Button
                  id="p-invited-friends-button"
                  icon="pi pi-users"
                  className="p-button p-button-warning m-0"
                  style={{ height: 30, width: 50 }}
                  onClick={openInvitedFriendsDialog}
                />
              </td>
            </tr>

          </tbody>
        </table>
      ) :
        <div style={{ position: 'relative', top: '50%', left: '50%' }}>
          <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="4" />
        </div>
      }
      <CustomerAddCkpDialog
        visible={addCkpDialog}
        onHide={hideAddCkpDialog}
        isLoading={addCkpIsLoading}
        saveNotificationMessage={saveNotificationMessage}
      />
      <Dialog
        visible={addressDialog}
        onHide={hideAddressDialog}
        footer={
          <div>
            <Button label="OK" icon="pi pi-check" onClick={hideAddressDialog} />
          </div>
        }
      >
        <div className="datatable-crud">
          <AddressDataTable customerId={customerId ?? null} />
        </div>
      </Dialog>
      <Dialog
        visible={invitedFriendsDialog}
        onHide={hideInvitedFriendsDialog}
        footer={
          <div>
            <Button label="OK" icon="pi pi-check" onClick={hideInvitedFriendsDialog} />
          </div>
        }
      >
        <div className="datatable-crud">
          <CustomerInvitedFriendsDataTable customerId={customerId ?? null} />
        </div>
      </Dialog>

      <Dialog
        visible={detailedInfoDialog}
        onHide={hideDetailedInfoDialog}
        style={{ minWidth: '500px', minHeight: '300px' }}
        footer={
          <div>
            <Button label="OK" icon="pi pi-check" onClick={hideDetailedInfoDialog} />
          </div>
        }
      >
        <div className="datatable-crud">
          <CustomerDetailedInfoDataTable customerId={customerId ?? null} />
        </div>
      </Dialog>
    </>
  );
};

export default CustomerDetailDataTable;

import { Toast } from "primereact";
import { toastError, toastSuccess } from "@/utils";
import { addProduct, addProductImages } from "@/services";
import { useMutation, useQueryClient } from "react-query";

export const useAddProductImagesData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const { mutate,
        isLoading: addProductImagesIsLoading,
        isSuccess: addProductImagesIsSuccess
    } = useMutation(
        async (productImages: FormData) => await addProductImages(productImages),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("products");
                toastSuccess(toast, "Product images added");
            },
            onError: (error: any) => {
                let errorMessage = "Product images not added";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return { mutate, addProductImagesIsLoading, addProductImagesIsSuccess };
};

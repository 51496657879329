import { QueryFunctionContext } from "react-query";
import { axiosInstance } from "@/api/axios.instance";
import { ICustomer, INotificationMessage } from "@/models";

export const getCustomers = async (
  page: number | null,
  rowPerPage: number | null,
  isDeleted: boolean | null,
  isInactive: boolean | null,
  email: String | null,
  orderBy: String | null,
  isDesc: boolean | null,
): Promise<ICustomer[]> => {
  const response = await axiosInstance.get(`/customer/pageSort`, {
    params: { page: page, limit: rowPerPage, isDeleted: isDeleted ?? false, isInactive: isInactive ?? false, email: email ?? '', orderBy: orderBy, isDesc: isDesc ?? true }
  }).then((res) => res.data);

  response.content = response.content.map((item: any) => ({
    ...item,
    totalElements: response.totalElements,
  }));

  return response.content;
}


export const updateCustomer = async (customer: ICustomer) =>
  await axiosInstance.put(`/customer`, customer);

export const deleteCustomer = async (customerId: number) =>
  await axiosInstance.delete(`/customer/${customerId}`);

export const getCustomerBasic = async (
  customerId: number,
): Promise<ICustomer> =>
  await axiosInstance.get(`/customer/${customerId}`).then((res) => res.data);

export const getCustomerDetailedInfo = async (
  customerId: number): Promise<any> => {
  const response = await axiosInstance.get(`/customer/detailedInfo/${customerId}`);
  // Bu şekilde liste halinde dönen veriyi map'e dönüştürebiliyoruz. Farklı yerlerde kullanabilirsin.
  return Object.fromEntries(response.data);
};

export const getCustomerInvitedFriends = async (
  customerId: number | null,
): Promise<ICustomer> =>
  await axiosInstance
    .get(`/customer/getCustomerInvitedFriends`, {
      params: { customerId: customerId }
    }).then((res) => res.data);

export const getCustomer = async ({
  queryKey,
}: QueryFunctionContext<[string, bigint | null]>): Promise<ICustomer> => {
  const [, customerId] = queryKey;
  return await axiosInstance
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    .get(`/customer/${customerId}`)
    .then((res) => res.data);
};

export const addCkpToCustomer = async (customerId: number | null, notificationMessage: INotificationMessage | null) =>
  await axiosInstance.post(`/customer/addMoney`, null, {
    params: {
      customerId: customerId,
      amount: notificationMessage?.amount,
      msgTitle: notificationMessage?.title,
      msgText: notificationMessage?.text,
      msgTitleSecond: notificationMessage?.titleScnd,
      msgTextSecond: notificationMessage?.textScnd,
    }
  });

export const getCustomerCkpHistory = async (
  customerId: number | null,
): Promise<any> =>
  await axiosInstance
    .get(`/customer/getCustomerCKPHistoryLogs`, {
      params: { customerId: customerId }
    }).then((res) => res.data);
import { Toast } from "primereact";
import { ICharity } from "@/models";
import { addCharity } from "@/services";
import { toastError, toastSuccess } from "@/utils";
import { useMutation, useQueryClient } from "react-query";

export const useAddCharityData = (toast: React.RefObject<Toast>) => {
    const queryClient = useQueryClient();
    const {
        mutate,
        isLoading: addCharityIsLoading,
        isSuccess: addCharityIsSuccess,
    } = useMutation(
        async (charity: Omit<ICharity, "imageUrl">) => await addCharity(charity),
        {
            onSuccess: () => {
                queryClient.invalidateQueries("charities");
                toastSuccess(toast, "Charity added");
            },
            onError: (error: any) => {
                let errorMessage = "Charity not added";
                if (error.response && error.response.data && error.response.data.message) {
                    errorMessage = error.response.data.message;
                }
                toastError(toast, `${errorMessage}`);
            },
        },
    );
    return { mutate, addCharityIsLoading, addCharityIsSuccess };
};

import { Badge, Button, Column, Toast } from "primereact";
import { useEffect, useRef, useState } from "react";
import {
    DataTableSearchHeader,
    DataTableCrud,
    DataTableActionTemplate,
    DataTableDeleteDialog,
    NotificationMessageDialog,
    DataTableToolbar,
    GeneralImageUploadDialog,
    ImageUploadResultDialog,
} from "@/components";
import { INotificationMessage } from "@/models";
import { ROLE, initialNotificationMessage, notificationMessageFilters } from "@/constants";
import {
    useGetNotificationMessagesData,
    useAddNotificationMessageData,
    useUpdateNotificationMessageData,
    useDeleteNotificationMessageData,
    useRole,
    useUploadGeneralImageData,
} from "@/hooks";

const NotificationMessagesDataTable = () => {
    const { role } = useRole();

    const [filters, setFilters] = useState(notificationMessageFilters);
    const [isAddNotificationMessage, setIsAddNotificationMessage] = useState(false);
    const [notificationMessageDialog, setNotificationMessageDialog] = useState(false);
    const [deleteNotificationMessageDialog, setDeleteNotificationMessageDialog] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState<INotificationMessage>(initialNotificationMessage);
    const [selectedNotificationMessage, setSelectedNotificationMessage] = useState<INotificationMessage | null>(null);
    const [imageDialog, setImageDialog] = useState(false);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [imageResultDialog, setImageResultDialog] = useState(false);


    const toast = useRef<Toast>(null);

    const { notificationMessages, getNotificationMessagesIsLoading } = useGetNotificationMessagesData();
    const {
        mutate: addNotificationMessageMutate,
        addNotificationMessageIsLoading,
        addNotificationMessageIsSuccess,
    } = useAddNotificationMessageData(toast);
    const {
        mutate: updateNotificationMessageMutate,
        updateNotificationMessageIsLoading,
        updateNotificationMessageIsSuccess,
    } = useUpdateNotificationMessageData(toast);
    const { mutate: deleteNotificationMessageMutate } = useDeleteNotificationMessageData(toast);
    const { mutate: uploadImageMutate, uploadGeneralImageIsLoading, uploadGeneralImageIsSuccess } = useUploadGeneralImageData(toast, (url: string) => {
        setImageUrl(url);
    });

    useEffect(() => {
        if (addNotificationMessageIsSuccess || updateNotificationMessageIsSuccess) {
            hideNotificationMessageDialog();
        }
    }, [addNotificationMessageIsSuccess, updateNotificationMessageIsSuccess]);

    useEffect(() => {
        if (uploadGeneralImageIsSuccess) {
            hideImageDialog();
            setImageResultDialog(true);
        }
    }, [uploadGeneralImageIsSuccess]);

    const openAddNotificationMessageDialog = async () => {
        setNotificationMessage(initialNotificationMessage);
        setIsAddNotificationMessage(true);
        setNotificationMessageDialog(true);
    };

    const openEditNotificationMessageDialog = (rowData: INotificationMessage) => {
        setNotificationMessage({ ...rowData });
        setIsAddNotificationMessage(false);
        setNotificationMessageDialog(true);
    };
    const hideNotificationMessageDialog = () => {
        setNotificationMessageDialog(false);
    };
    const saveNotificationMessage = (notificationMessage: INotificationMessage) => {
        isAddNotificationMessage ? addNotificationMessageMutate(notificationMessage) : updateNotificationMessageMutate(notificationMessage);
    };

    const openDeleteNotificationMessageDialog = (notificationMessage?: INotificationMessage) => {
        notificationMessage && setNotificationMessage(notificationMessage);
        setDeleteNotificationMessageDialog(true);
    };
    const hideDeleteNotificationMessageDialog = () => {
        setDeleteNotificationMessageDialog(false);
    };
    const deleteNotificationMessage = () => {
        notificationMessage.id && deleteNotificationMessageMutate(notificationMessage.id);
        hideDeleteNotificationMessageDialog();
    };

    const saveImage = (file: FileList) => {
        const formData = new FormData();
        formData.append("type", `NOTIFICATION`);
        formData.append("file", file[0]);
        uploadImageMutate({ image: formData });
    };

    const openImageDialog = () => {
        setImageDialog(true);
    };

    const hideImageDialog = () => {
        setImageDialog(false);
    };

    return (
        <>
            <Toast ref={toast} />
            {(role !== ROLE.OPERATOR) && (
                <DataTableToolbar
                    openAddDialog={openAddNotificationMessageDialog}
                >
                    <Button
                        id="p-image-button"
                        label="Upload Image"
                        icon="pi pi-image"
                        className="p-button-primary mr-2"
                        onClick={() => openImageDialog()}
                    />
                </DataTableToolbar>
            )}
            <DataTableCrud
                value={notificationMessages}
                selection={selectedNotificationMessage}
                onSelectionChange={(e: any) => setSelectedNotificationMessage(e.value)}
                loading={getNotificationMessagesIsLoading}
                title="notification messages"
                header={
                    <DataTableSearchHeader
                        title="Manage Notification Messages"
                        filters={filters}
                        onChange={(e: any) => setFilters(e)}
                    />
                }
                filters={filters}
                filterDisplay="row"
                globalFilterFields={["msgCode", "title", "text"]}
                emptyMessage="Notification messages not found"
            >
                <Column
                    field="msgCode"
                    header="Message Code"
                    sortable
                    style={{ minWidth: "12rem" }}
                    filter
                    filterPlaceholder="Search by message code"
                    body={(rowData: INotificationMessage) => {
                        return (
                            <Badge value={rowData?.msgCode} className={getBadgeColor(rowData?.msgCode)} />
                        );
                    }}
                ></Column>
                <Column
                    field="title"
                    header="Title"
                    sortable
                    style={{ minWidth: "12rem" }}
                    filter
                    filterPlaceholder="Search by title"
                ></Column>
                <Column
                    field="text"
                    header="Text"
                    sortable
                    style={{ minWidth: "12rem" }}
                    filter
                    filterPlaceholder="Search by text"
                ></Column>
                {(role !== ROLE.OPERATOR) && (
                    <Column
                        body={(rowData: INotificationMessage) => (
                            <DataTableActionTemplate
                                openEditDialog={() => openEditNotificationMessageDialog(rowData)}
                                openDeleteDialog={() => openDeleteNotificationMessageDialog(rowData)}
                            />
                        )}
                        exportable={false}
                        style={{ minWidth: "12rem" }}
                    ></Column>
                )}
            </DataTableCrud>

            <DataTableDeleteDialog
                visible={deleteNotificationMessageDialog}
                data={selectedNotificationMessage ?? notificationMessage}
                onHide={hideDeleteNotificationMessageDialog}
                onDelete={deleteNotificationMessage}
            />

            <NotificationMessageDialog
                visible={notificationMessageDialog}
                notificationMessage={notificationMessage}
                isLoading={isAddNotificationMessage ? addNotificationMessageIsLoading : updateNotificationMessageIsLoading}
                isAddNotification={isAddNotificationMessage}
                onHide={hideNotificationMessageDialog}
                saveNotificationMessage={saveNotificationMessage}
            />

            <GeneralImageUploadDialog
                visible={imageDialog}
                onHide={hideImageDialog}
                saveImage={saveImage}
                imageUrl={imageUrl ?? ''}
                isLoading={uploadGeneralImageIsLoading}
            />

            <ImageUploadResultDialog
                visible={imageResultDialog}
                imageUrl={imageUrl ?? ''}
                onHide={() => setImageResultDialog(false)}
            />
        </>
    );
};

export default NotificationMessagesDataTable;

function getBadgeColor(msgCode: string | null) {
    switch (msgCode) {
        case "NCS":
            return "p-badge-success";
        case "CMP_1000":
            return "p-badge-info";
        case "CMP_3000":
            return "p-badge-warning";
        case "CMP_5000":
            return "p-badge-danger";
        case "CMP_10000":
            return "p-badge-primary";
        case "CMP_20000":
            return "p-badge-secondary";
        case "ALL_CUSTOMERS":
            return "p-badge-success";
        case "APP_OPEN_REMINDER":
            return "p-badge-tertiary";
        case "SINGLE_NOTIFICATION":
            return "p-badge-quaternary";
        case "ORDER_SENT_NOTIFICATION":
            return "p-badge-info";
        default:
            return "p-badge-rose";
    }
}

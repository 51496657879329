import { CharityDataTable } from "@/components";

const Charities = () => {
    return (
        <div className="datatable-crud">
            <div className="card">
                <CharityDataTable />
            </div>
        </div>
    );
};

export default Charities;

import { ICharity } from "@/models";
import { Column, DataTable, Dialog, Image } from "primereact";
import { DataTableDialogFooter } from "../../common";
import { CHARITY_STATUS } from "@/constants/enums";
import React, { SyntheticEvent, useState } from "react";
import NoImage from "@assets/images/no-image.png";
import { useGetCharitiesData } from "@/hooks";


interface PopupCharitySelectionDialogProps {
    visible: boolean;
    onHide: () => void;
    onCharitySelect: (charity: ICharity) => void;
}


const PopupCharitySelectionDialog: React.FC<PopupCharitySelectionDialogProps> = ({
    visible,
    onHide,
    onCharitySelect,
}) => {

    const [selectedCharity, setSelectedCharity] = useState<ICharity>();

    const { charities, getCharitiesIsLoading } = useGetCharitiesData();

    const hideCharitySelectionDialog = () => {
        onHide();
    };

    const handleSelectedCharity = (charity: ICharity) => {
        onCharitySelect(charity);
        hideCharitySelectionDialog();
    };

    const imageBodyTemplate = (rowData: ICharity) => {
        return (
            <Image
                src={`${rowData.imageUrl}`}
                alt="charity"
                height="80"
                width="80"
                preview
                className="charity-image"
                onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
                    (e.target as HTMLImageElement).src = NoImage;
                }}
            />
        );
    };

    return (
        <Dialog
            visible={visible}
            footer={
                <DataTableDialogFooter
                    loading={getCharitiesIsLoading}
                    hideDialog={hideCharitySelectionDialog}
                    handleSubmit={() => {
                        if (selectedCharity !== undefined) {
                            handleSelectedCharity(selectedCharity);
                        }
                    }}
                    submitButtonText="Select"
                />
            }
            header="Select Charity"
            modal
            className="p-fluid"
            onHide={hideCharitySelectionDialog}
        >
            <DataTable
                value={charities?.filter((charity) => charity.status === CHARITY_STATUS.ACTIVE)}
                title="active charities"
                paginator={false}
                selection={selectedCharity}
                onSelectionChange={(e) => setSelectedCharity(e.value)}
                loading={getCharitiesIsLoading}
            >
                <Column
                    selectionMode="single"
                    headerStyle={{ width: "3rem" }}
                    exportable={false}
                ></Column>
                <Column field="name" header={"Name"} style={{ minWidth: "6rem" }}></Column>
                <Column field="image" header="Image" body={imageBodyTemplate}></Column>
                <Column field="charityTargetValue" header={"Target Value"} style={{ minWidth: "6rem" }}></Column>
            </DataTable>
        </Dialog>
    );
};

export default PopupCharitySelectionDialog;

import { Column } from "primereact";
import React, { useState } from "react";
import { DataTableSearchHeader, DataTableCrud } from "@/components";
import { customerFilters } from "@/constants";
import { useGetAddressesData } from "@/hooks";
import { useTranslation } from "react-i18next";
import { useCountryCode } from "@/contexts/CountryContext";

interface Props {
    customerId: number | null;
}

const AddressDataTable: React.FC<Props> = ({ customerId }) => {
    const [filters, setFilters] = useState(customerFilters);

    const { addresses, getAddressesIsLoading } = useGetAddressesData(customerId);
    const { t } = useTranslation();

    const { countryCode } = useCountryCode();

    return (
        <>
            <DataTableCrud
                value={addresses}
                loading={getAddressesIsLoading}
                title="addresses"
                header={
                    <DataTableSearchHeader
                        title={t("User's Addresses")}
                        filters={filters}
                        onChange={(e: any) => setFilters(e)}
                    />
                }
                filters={filters}
            >
                <Column
                    field="title"
                    header={t("Title")}
                    sortable
                    style={{ minWidth: "12rem" }}
                ></Column>

                <Column
                    field="name"
                    header={t("Name")}
                    sortable
                    style={{ minWidth: "12rem" }}
                ></Column>

                <Column
                    field="surname"
                    header={t("Surname")}
                    sortable
                    style={{ minWidth: "12rem" }}
                ></Column>

                {countryCode === 'UK' && (<Column
                    field="houseName"
                    header="House Name/Number"
                    sortable
                    style={{ minWidth: "12rem" }}
                ></Column>)}

                {countryCode === 'UK' && (<Column
                    field="streetName"
                    header="Street Name"
                    sortable
                    style={{ minWidth: "12rem" }}
                ></Column>)}

                <Column
                    field="city"
                    header={t("City")}
                    sortable
                    style={{ minWidth: "12rem" }}
                ></Column>

                <Column
                    field="district"
                    header={countryCode === 'UK' ? 'County' : "District"}
                    sortable
                    style={{ minWidth: "12rem" }}
                ></Column>

                <Column
                    field="addressDescription"
                    header={countryCode === 'UK' ? 'Postcode' : "Address Description"}
                    sortable
                    style={{ minWidth: "12rem" }}
                ></Column>

                <Column
                    field="phoneNumber"
                    header={t("Phone Number")}
                    sortable
                    style={{ minWidth: "12rem" }}
                ></Column>
            </DataTableCrud>
        </>
    );
};

export default AddressDataTable;

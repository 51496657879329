import { ICharity } from "@/models";
import React, { SyntheticEvent } from "react";
import NoImage from "@assets/images/no-image.png";
import { Button, Dialog, ProgressSpinner, Image } from "primereact";

interface Props {
    rowData: ICharity | null;
    visible: boolean;
    isLoading?: boolean;
    onHide: () => void;
    saveImage: (files: FileList) => void;
}

const CharityShareImageUploadDialog: React.FC<Props> = ({
    rowData,
    visible,
    onHide,
    saveImage,
    isLoading,
}) => {

    const closeDialog = () => {
        onHide();
    };


    const openImageUploadDialog = () => {
        const fileInput = document.createElement("input");
        fileInput.type = "file";
        fileInput.accept = "image/*";

        fileInput.onchange = (event) => {
            const inputElement = event.target as HTMLInputElement;
            const files = inputElement.files;
            if (files && files.length > 0) {
                saveImage(files);
            }
        };

        fileInput.click();
    };


    return (
        <Dialog
            visible={visible}
            style={{ width: "30%", minHeight: "100px" }}
            header="Image Upload"
            modal
            className="p-fluid"
            onHide={closeDialog}
        >
            {!isLoading ? (
                <>
                    <span style={{ color: "red" }}>
                        {'Image size must be 1920px width and 1080px height'}
                    </span>
                    <div style={{ display: "flex", alignItems: "center", width: "100%", height: "100%", paddingTop: "20px" }}>
                        <Image
                            src={rowData?.charitySharePageImage ?? ''}
                            alt="image"
                            height="100%"
                            width="100%"
                            style={{
                                flex: "1",
                                display: "flex",
                                justifyContent: "flex-start",
                                marginRight: "110px",
                            }}
                            preview
                            className="charity-share-image"
                            onError={(e: SyntheticEvent<HTMLImageElement, Event>) => {
                                (e.target as HTMLImageElement).src = NoImage;
                            }}
                        />

                        <Button
                            id="p-upload-image-button2"
                            className="p-button-rounded m-2"
                            icon="pi pi-upload"
                            onClick={() => openImageUploadDialog()}
                            style={{
                                textAlign: "center",
                                textShadow: "1px 1px 2px black",
                                fontWeight: "bold",
                                marginTop: "10px",
                                marginLeft: "auto",
                            }}
                        >
                        </Button>
                    </div>

                </>
            ) : (
                <div
                    className="flex align-items-center"
                    style={{ height: 200, width: "100%" }}
                >
                    <ProgressSpinner style={{ width: "50px", height: "50px" }} />
                </div>
            )}
        </Dialog>
    );
};

export default CharityShareImageUploadDialog;

import * as Yup from "yup";

export const charitySchema = Yup.object().shape({
    name: Yup.string()
        .min(2, "Please enter at least 2 characters!")
        .required("Name is required"),
    charityInstitutionName: Yup.string().nullable()
        .min(2, "Please enter at least 2 characters!")
        .required("Institution Name is required"),
    longDesc: Yup.string().nullable()
        .min(2, "Please enter at least 2 characters!")
        .required("Description is required"),
    shortDesc: Yup.string().nullable()
        .min(2, "Please enter at least 2 characters!")
        .required("Donation Info is required"),
    charityTargetValue: Yup.number().nullable()
        .min(1, "Please enter at least 1 character!")
        .required("Target Value is required"),
    charityShareCode: Yup.string().nullable()
        .min(2, "Please enter at least 2 characters!")
        .required("Share Code is required"),
    charityShareMessageTitle: Yup.string().nullable()
        .min(2, "Please enter at least 2 characters!")
        .required("Mobile Share Title is required"),
    charityShareMessageText: Yup.string().nullable()
        .min(2, "Please enter at least 2 characters!")
        .required("Mobile Share Text is required"),
    charityShareTitle: Yup.string().nullable()
        .min(2, "Please enter at least 2 characters!")
        .required("Web Page Title is required"),
    charityShareMessage: Yup.string().nullable()
        .min(2, "Please enter at least 2 characters!")
        .required("Web Page Text is required"),
});

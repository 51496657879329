import { useFormik } from "formik";
import classNames from "classnames";
import { ICampaign, IPeriod } from "@/models";
import { useTranslation } from "react-i18next";
import { DataTableDialogFooter } from "../../common";
import { statusRadioButtonFields } from "@/constants";
import { CAMPAIGN_TYPES, STATUS } from "@/constants/enums";
import React, { RefObject, useEffect, useState } from "react";
import { Dialog, InputSwitch, TabMenu, Toast } from "primereact";
import { capitalizeFirstLetter, isFormFieldValid } from "@/utils";
import { useAddDrawPeriodsData, useGetDrawPeriodsData, useUpdateDrawPeriodsData } from "@/hooks";
import {
    DropdownField,
    ErrorField,
    InputField,
    InputNumberField,
    RadioButtonField,
    RadioButtonBoxField,
    CalendarField,
} from "@/components/FormFields";
import { campaignSchema } from "@/validations";

interface CampaignDialogProps {
    visible: boolean;
    campaign: Omit<ICampaign, "imageUrl">;
    isLoading: boolean;
    onHide: () => void;
    addCampaign: (campaign: Omit<ICampaign, "imageUrl">) => void;
    isAddItem?: boolean;
    toast: RefObject<Toast>;
}

const items = [
    { label: "Main", icon: "pi pi-fw pi-home" },
    { label: "SecondLanguage", icon: "pi pi-fw pi-language" },
];

const CampaignDialog: React.FC<CampaignDialogProps> = ({
    visible,
    campaign,
    isLoading,
    onHide,
    addCampaign,
    isAddItem,
    toast,
}) => {
    const [status, setStatus] = useState(STATUS.INACTIVE);
    const [activeIndex, setActiveIndex] = useState(0);
    const { t } = useTranslation();

    const {
        mutate: addDrawPeriodsMutate,
        addDrawPeriodIsLoading,
        addDrawPeriodIsSuccess,
    } = useAddDrawPeriodsData(toast);
    const {
        mutate: updateDrawPeriodsMutate,
        updateDrawPeriodsIsLoading,
        updateDrawPeriodsIsSuccess,
    } = useUpdateDrawPeriodsData(toast);

    const { drawPeriods, getDrawPeriodsIsLoading } = useGetDrawPeriodsData(campaign?.id);

    const hideDialog = () => {
        console.log("here");
        onHide();
        formik.resetForm();
    };

    useEffect(() => {
        console.log("here");
        setStatus(campaign.status ?? STATUS.INACTIVE);
    }, [campaign]);

    useEffect(() => {
        if (drawPeriods && drawPeriods.length > 0) {
            const periodString = drawPeriods.map((period) => `${period?.startTime ?? ''} - ${period?.endTime ?? ''}`).join(" | ");

            formik.setFieldValue("period", periodString);
        }
    }, [drawPeriods]);

    const formatDate = (dateString: any) => {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Ay değeri 0-11 arası olduğu için +1 ekliyoruz
        const day = String(date.getDate()).padStart(2, "0");
        const hours = String(date.getHours()).padStart(2, "0");
        const minutes = String(date.getMinutes()).padStart(2, "0");
        const seconds = String(date.getSeconds()).padStart(2, "0");

        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return formattedDate;
    };

    const campaignOptions = [
        { name: "Step Count", value: "SC" },
        { name: "Daily Watch", value: "DW" },
        { name: "Regular Customer", value: "RC" },
        { name: "One Time", value: "WC" },
        { name: "Draw", value: "DRW" },
        { name: "Direct", value: "DRT" },
        { name: "Time Based Draw", value: "TBD" },
    ]?.map((campaign) => ({
        label: campaign.name,
        value: campaign.value,
    }));

    const periodOptions = [
        {
            name: "11:00 - 19:00 | 19:00 - 03:00 | 03:00 - 11:00", value: "11:00 - 19:00 | 19:00 - 03:00 | 03:00 - 11:00",
        },
    ]?.map((campaign) => ({
        label: campaign.name,
        value: campaign.value,
    }));

    const setCampaignStatus = (value: any) => {
        setStatus(value);
        formik.values.status = value;
    };

    const addDrawPeriods = (periods: IPeriod[]) => {
        addDrawPeriodsMutate(periods);
    };

    const updateDrawPeriods = (periods: IPeriod[]) => {
        updateDrawPeriodsMutate(periods);
    };

    const formik = useFormik({
        initialValues: campaign,
        enableReinitialize: true,
        validationSchema: campaignSchema,
        onSubmit: (data) => {
            data.sellingPoint = data.sellingPoint === 0 ? null : data.sellingPoint;
            data.drawJoinEndDate = formatDate(data.drawJoinEndDate);
            data.drawDate = formatDate(data.drawDate);
            data.prizeText = data.prizeText === '' ? null : data.prizeText;
            data.prizeTextScnd = data.prizeTextScnd === '' ? null : data.prizeTextScnd;
            data.subInfoText = data.subInfoText === '' ? null : data.subInfoText;
            data.subInfoTextScnd = data.subInfoTextScnd === '' ? null : data.subInfoTextScnd;
            data.linkText = data.linkText === '' ? null : data.linkText;
            data.linkTextScnd = data.linkTextScnd === '' ? null : data.linkTextScnd;
            data.directPopUpColorCode1 = data.directPopUpColorCode1 === '' ? null : data.directPopUpColorCode1;
            data.directPopUpColorCode2 = data.directPopUpColorCode2 === '' ? null : data.directPopUpColorCode2;
            data.directPopUpButtonColorCode = data.directPopUpButtonColorCode === '' ? null : data.directPopUpButtonColorCode;
            addCampaign(data);
            setStatus(STATUS.INACTIVE);

            if (data.period && campaign.type === CAMPAIGN_TYPES.TBD) {
                const periods: IPeriod[] = data.period.split(" | ").map((periodString, index) => {
                    const [startTime, endTime] = periodString.split(" - ");
                    return {
                        id: drawPeriods && drawPeriods.length > 0 ? drawPeriods[index].id : null,
                        campaignId: data.id,
                        startTime,
                        endTime,
                    };
                }) as IPeriod[];

                if (drawPeriods && drawPeriods.length > 0) {
                    updateDrawPeriods(periods)
                } else {
                    addDrawPeriods(periods)
                }
            }
        },
    });

    return (
        <Dialog
            visible={visible}
            style={{ width: "450px" }}
            header={t("Campaign Details")}
            modal
            className="p-fluid"
            footer={
                <DataTableDialogFooter
                    loading={isLoading}
                    hideDialog={hideDialog}
                    handleSubmit={formik.handleSubmit}
                />
            }
            onHide={hideDialog}
        >
            <TabMenu
                model={items}
                className="mb-5"
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
            />
            <form onSubmit={formik.handleSubmit}>
                {activeIndex === 0 ? (
                    <>
                        <InputField
                            id="name"
                            value={formik.values.name}
                            type="text"
                            required
                            autoFocus={true}
                            onChange={formik.handleChange}
                            labelName="name"
                            labelText={capitalizeFirstLetter("name")}
                            getFormErrorMessage={() => <ErrorField formik={formik} name={"name"} />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "name"),
                            })}
                        />

                        <DropdownField
                            id="type"
                            value={formik.values.type}
                            type="text"
                            required
                            options={campaignOptions}
                            onChange={formik.handleChange}
                            labelName="type"
                            labelText={capitalizeFirstLetter("type")}
                            getFormErrorMessage={() => <ErrorField formik={formik} name={"type"} />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "type"),
                            })}
                        />

                        <InputField
                            id="description"
                            value={formik.values.description}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="description"
                            labelText={capitalizeFirstLetter("description")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name={"description"} />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "description"),
                            })}
                        />

                        <InputNumberField
                            id="bonus"
                            inputId="bonus"
                            mode="currency"
                            currency="SMP"
                            visible={formik.values.type !== CAMPAIGN_TYPES.DRW && formik.values.type !== null}
                            type="text"
                            showButtons
                            value={formik.values.bonus ?? ""}
                            required
                            onValueChange={formik.handleChange}
                            labelName="bonus"
                            labelText={capitalizeFirstLetter("Bonus")}
                            getFormErrorMessage={() => <ErrorField formik={formik} name="bonus" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "bonus"),
                            })}
                        />

                        <InputNumberField
                            id="limitValue"
                            inputId="limitValue"
                            type="text"
                            showButtons
                            value={formik.values.limitValue ?? ""}
                            visible={formik.values.type !== CAMPAIGN_TYPES.DRT}
                            required
                            onValueChange={formik.handleChange}
                            labelName="limitValue"
                            labelText={capitalizeFirstLetter("Adv count for bonus")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="limitValue" />
                            )}
                            min={1}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "limitValue"),
                            })}
                        />


                        <InputField
                            id="directPopUpColorCode1"
                            value={formik.values.directPopUpColorCode1 ?? ''}
                            type="text"
                            visible={formik.values.type === CAMPAIGN_TYPES.DRT}
                            required
                            maxLength={6}
                            onChange={formik.handleChange}
                            labelName="directPopUpColorCode1"
                            labelText="BG Top HEX Color Code"
                            getFormErrorMessage={() => <ErrorField formik={formik} name={"directPopUpColorCode1"} />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "directPopUpColorCode1"),
                            })}
                            placeholder="Enter HEX code or leave blank (eg: FFFFFF)"
                        />

                        <InputField
                            id="directPopUpColorCode2"
                            value={formik.values.directPopUpColorCode2 ?? ''}
                            type="text"
                            required
                            visible={formik.values.type === CAMPAIGN_TYPES.DRT}
                            maxLength={6}
                            onChange={formik.handleChange}
                            labelName="directPopUpColorCode2"
                            labelText="BG Bottom HEX Color Code"
                            getFormErrorMessage={() => <ErrorField formik={formik} name={"directPopUpColorCode2"} />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "directPopUpColorCode2"),
                            })}
                            placeholder="Enter HEX code or leave blank (eg: FFFFFF)"
                        />

                        <InputField
                            id="directPopUpButtonColorCode"
                            value={formik.values.directPopUpButtonColorCode ?? ''}
                            type="text"
                            required
                            visible={formik.values.type === CAMPAIGN_TYPES.DRT}
                            maxLength={6}
                            onChange={formik.handleChange}
                            labelName="directPopUpButtonColorCode"
                            labelText="Button HEX Color Code"
                            getFormErrorMessage={() => <ErrorField formik={formik} name={"directPopUpButtonColorCode"} />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "directPopUpButtonColorCode"),
                            })}
                            placeholder="Enter HEX code or leave blank (eg: FFFFFF)"
                        />
                        {formik.values.type === CAMPAIGN_TYPES.DRT && (
                            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <span>Clickable after completion</span>
                                <InputSwitch
                                    id="showStockAmount"
                                    checked={formik.values.isClickable ?? true}
                                    onChange={(e) => {
                                        formik.setFieldValue('isClickable', e.value);
                                    }}
                                    className={classNames({
                                        "p-invalid": isFormFieldValid(formik, "isClickable"),
                                    })}
                                />
                            </div>)}

                        <CalendarField
                            id="drawJoinEndDate"
                            labelName="drawJoinEndDate"
                            labelText="Draw Join End Date"
                            visible={formik.values.type === CAMPAIGN_TYPES.DRW || formik.values.type === CAMPAIGN_TYPES.TBD}
                            value={formik.values.drawJoinEndDate ? new Date(formik.values.drawJoinEndDate) : undefined}
                            showTime={true}
                            showIcon
                            dateFormat="dd-mm-yy"
                            required
                            minDate={new Date()}
                            onChange={(e) => {
                                formik.handleChange(e);

                                const drawDate = formik.values.drawDate ? new Date(formik.values.drawDate) : null;

                                if (drawDate && e.value !== undefined && drawDate > e.value) {
                                    formik.setFieldError("drawJoinEndDate", "Draw Join End Date should be before Draw Date");
                                } else {
                                    formik.setFieldError("drawJoinEndDate", "");
                                }
                            }}
                        />

                        <CalendarField
                            id="drawDate"
                            labelName="drawDate"
                            labelText="Draw Date"
                            visible={formik.values.type === CAMPAIGN_TYPES.DRW || formik.values.type === CAMPAIGN_TYPES.TBD}
                            value={formik.values.drawDate ? new Date(formik.values.drawDate) : undefined}
                            showTime={true}
                            showIcon
                            disabled={(formik.values.type !== CAMPAIGN_TYPES.DRW && formik.values.type !== CAMPAIGN_TYPES.TBD) || !formik.values.drawJoinEndDate}
                            dateFormat="dd-mm-yy"
                            required
                            minDate={formik.values.drawJoinEndDate ? new Date(formik.values.drawJoinEndDate) : undefined}
                            onChange={(e) => {
                                formik.handleChange(e);

                                const drawJoinEndDate = formik.values.drawJoinEndDate ? new Date(formik.values.drawJoinEndDate) : null;

                                if (drawJoinEndDate && e.value !== undefined && drawJoinEndDate < e.value) {
                                    formik.setFieldError("drawDate", "Draw Date should be after Draw Join End Date");
                                } else {
                                    formik.setFieldError("drawDate", "");
                                }
                            }}
                        />

                        <DropdownField
                            id="period"
                            value={formik.values.period ?? ''}
                            type="text"
                            required
                            options={periodOptions}
                            onChange={formik.handleChange}
                            labelName="period"
                            labelText={capitalizeFirstLetter("period")}
                            visible={formik.values.type === CAMPAIGN_TYPES.TBD && !isAddItem}
                            getFormErrorMessage={() => <ErrorField formik={formik} name={"type"} />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "type"),
                            })}
                        />

                        <InputField
                            id="prizeText"
                            value={formik.values.prizeText ?? ''}
                            type="text"
                            visible={formik.values.type === CAMPAIGN_TYPES.DRW || formik.values.type === CAMPAIGN_TYPES.TBD}
                            required
                            onChange={formik.handleChange}
                            labelName="prizeText"
                            labelText="Prize Text"
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name={"prizeText"} />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "prizeText"),
                            })}
                        />

                        <InputField
                            id="subInfoText"
                            value={formik.values.subInfoText ?? ''}
                            type="text"
                            visible={formik.values.type === CAMPAIGN_TYPES.DRW || formik.values.type === CAMPAIGN_TYPES.TBD}
                            required
                            onChange={formik.handleChange}
                            labelName="subInfoText"
                            labelText="Sub Info Text"
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name={"subInfoText"} />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "subInfoText"),
                            })}
                        />

                        <InputField
                            id="linkText"
                            value={formik.values.linkText ?? ''}
                            type="text"
                            visible={formik.values.type === CAMPAIGN_TYPES.DRW || formik.values.type === CAMPAIGN_TYPES.TBD}
                            required
                            onChange={formik.handleChange}
                            labelName="linkText"
                            labelText="Link Text"
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name={"linkText"} />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "linkText"),
                            })}
                        />

                        <InputNumberField
                            id="stepCountLimit"
                            inputId="stepCountLimit"
                            type="text"
                            showButtons
                            visible={formik.values.type === CAMPAIGN_TYPES.SC}
                            value={formik.values.stepCountLimit ?? ""}
                            required
                            onValueChange={formik.handleChange}
                            labelName="stepCountLimit"
                            labelText={capitalizeFirstLetter("Step Count Limit")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="stepCountLimit" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "stepCountLimit"),
                            })}
                        />

                        <InputNumberField
                            id="amount"
                            inputId="amount"
                            type="text"
                            showButtons
                            visible={formik.values.type === CAMPAIGN_TYPES.DRW || formik.values.type === CAMPAIGN_TYPES.TBD}
                            value={formik.values.amount ?? ""}
                            required
                            onValueChange={formik.handleChange}
                            labelName="amount"
                            labelText={capitalizeFirstLetter("Amount")}
                            getFormErrorMessage={() => <ErrorField formik={formik} name="amount" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "amount"),
                            })}
                        />

                        <InputNumberField
                            id="sellingPoint"
                            inputId="sellingPoint"
                            showButtons
                            mode="currency"
                            currency="SMP"
                            visible={formik.values.type === CAMPAIGN_TYPES.DRW || formik.values.type === CAMPAIGN_TYPES.TBD}
                            value={formik.values.sellingPoint ?? 0}
                            required
                            onValueChange={formik.handleChange}
                            labelName="sellingPoint"
                            labelText={capitalizeFirstLetter("Price")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="sellingPoint" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "sellingPoint"),
                            })}
                        />

                        {isAddItem !== true && (<RadioButtonBoxField
                            style={{ margin: "30px 0" }}
                            className={classNames("field", {
                                "p-invalid": isFormFieldValid(formik, "status"),
                            })}
                        >
                            {statusRadioButtonFields.map((item, index) => (
                                <RadioButtonField
                                    inputId={`status${index}`}
                                    name={"status"}
                                    key={index}
                                    value={item}
                                    onChange={(e: any) => setCampaignStatus(e.target.value)}
                                    checked={status === item}
                                    labelName={`status${index}`}
                                    labelText={item}
                                />
                            ))}
                        </RadioButtonBoxField>)}
                    </>
                ) : (
                    <>
                        <InputField
                            id="name2lgn"
                            value={formik.values.name2lgn}
                            type="text"
                            required
                            autoFocus={true}
                            onChange={formik.handleChange}
                            labelName="nameScnd"
                            labelText={capitalizeFirstLetter("Second Language Name")}
                            getFormErrorMessage={() => <ErrorField formik={formik} name={"name"} />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "name"),
                            })}
                        />

                        <InputField
                            id="description2lng"
                            value={formik.values.description2lng}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="descriptionScnd"
                            labelText={capitalizeFirstLetter("Second Language Description")}
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name={"description"} />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "description"),
                            })}
                        />

                        <InputField
                            id="prizeTextScnd"
                            value={formik.values.prizeTextScnd ?? ''}
                            type="text"
                            visible={formik.values.type === CAMPAIGN_TYPES.DRW || formik.values.type === CAMPAIGN_TYPES.TBD}
                            required
                            onChange={formik.handleChange}
                            labelName="prizeTextScnd"
                            labelText="Second Language Prize Text"
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name={"prizeText"} />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "prizeText"),
                            })}
                        />

                        <InputField
                            id="subInfoTextScnd"
                            value={formik.values.subInfoTextScnd ?? ''}
                            type="text"
                            visible={formik.values.type === CAMPAIGN_TYPES.DRW || formik.values.type === CAMPAIGN_TYPES.TBD}
                            required
                            onChange={formik.handleChange}
                            labelName="subInfoTextScnd"
                            labelText="Second Language Sub Info Text"
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name={"subInfoText"} />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "subInfoText"),
                            })}
                        />

                        <InputField
                            id="linkTextScnd"
                            value={formik.values.linkTextScnd ?? ''}
                            type="text"
                            visible={formik.values.type === CAMPAIGN_TYPES.DRW || formik.values.type === CAMPAIGN_TYPES.TBD}
                            required
                            onChange={formik.handleChange}
                            labelName="linkTextScnd"
                            labelText="Second Language Link Text"
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name={"linkTextScnd"} />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "linkTextScnd"),
                            })}
                        />
                    </>
                )}
            </form>
        </Dialog>
    );
};

export default CampaignDialog;

import {
  DataTableCrud,
  DataTableSearchHeader,
} from "@/components";
import { useState } from "react";
import { Column } from "primereact";
import { drawFilters } from "@/constants";
import { useGetDonorData } from "@/hooks";

interface Props {
  charityId: number | null;
}

const DonorDataTable: React.FC<Props> = ({ charityId }) => {
  const [filters, setFilters] = useState(drawFilters);
  const { donor, getDonorDataIsLoading } = useGetDonorData(charityId);

  return (
    <>

      <DataTableCrud
        value={donor}
        header={
          <DataTableSearchHeader
            title="Donor List"
            filters={filters}
            onChange={(e) => setFilters(e)}
            customTitleStyles={{ fontSize: "1.1rem" }}
            inputStyles={{ width: "150px" }}
          />
        }
        filters={filters}
        loading={getDonorDataIsLoading}
        title="donors"
        emptyMessage="No donors found"
      >
        <Column
          field="customerEmail"
          header="Email"
          sortable
          style={{ minWidth: "12rem" }}
        />
        <Column
          field="customerName"
          header="Name"
          sortable
          style={{ minWidth: "12rem" }}
        />
        <Column
          field="customerSurname"
          header="Surname"
          sortable
          style={{ minWidth: "12rem" }}
        />
        <Column
          field="totalDonationAmount"
          header="Donation Amount"
          sortable
          style={{ minWidth: "12rem" }}
        />
      </DataTableCrud>
      <div style={{ height: "1rem" }} />
    </>
  );
};

export default DonorDataTable;

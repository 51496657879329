import {
    ErrorField,
    InputField,
    DropdownField,
    InputNumberField,
    DataTableDialogFooter,
} from "@/components";
import { useFormik } from "formik";
import classNames from "classnames";
import { ICharity } from "@/models";
import React, { useState } from "react";
import { statusTypes } from "@/constants";
import { Dialog, TabMenu } from "primereact";
import { charitySchema } from "@/validations";
import { CHARITY_TYPES } from "@/constants/enums";
import { isFormFieldValid } from "@/utils";

interface CharityDialogProps {
    visible: boolean;
    charity: Omit<ICharity, "imageUrl">;
    isLoading: boolean;
    isAddItem: boolean;
    onHide: () => void;
    addCharity: (charity: Omit<ICharity, "imageUrl">) => void;
}

const items = [
    { label: "Main", icon: "pi pi-fw pi-home" },
    { label: "Share Info", icon: "pi pi-fw pi-share-alt" },
];

const statusOptions = statusTypes?.map((status) => ({
    label: status,
    value: status,
}));

const CharityDialog: React.FC<CharityDialogProps> = ({
    visible,
    charity,
    isLoading,
    isAddItem,
    onHide,
    addCharity,
}) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const formik = useFormik({
        initialValues: charity,
        enableReinitialize: true,
        validationSchema: charitySchema,
        onSubmit: (data) => {
            data.type = CHARITY_TYPES.CHARITY;

            addCharity(data);
        },
    });

    const hideDialog = () => {
        formik.resetForm();
        onHide();
    };

    return (
        <Dialog
            visible={visible}
            style={{ width: "800px" }}
            header="Charity Details"
            modal
            className="p-fluid"
            footer={
                <DataTableDialogFooter
                    loading={isLoading}
                    hideDialog={hideDialog}
                    handleSubmit={formik.handleSubmit}
                />
            }
            onHide={hideDialog}
        >
            <TabMenu
                model={items}
                className="mb-5"
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
            />
            <form onSubmit={formik.handleSubmit}>
                {activeIndex === 0 ? (
                    <>
                        <InputField
                            id="name"
                            value={formik.values.name ?? ""}
                            type="text"
                            required
                            autoFocus={true}
                            onChange={formik.handleChange}
                            labelName="name"
                            labelText="Name"
                            getFormErrorMessage={() => <ErrorField formik={formik} name="name" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "name"),
                            })}
                        />
                        <InputField
                            id="charityInstitutionName"
                            value={formik.values.charityInstitutionName ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="charityInstitutionName"
                            labelText="Institution Name"
                            getFormErrorMessage={() => <ErrorField formik={formik} name="charityInstitutionName" />}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "charityInstitutionName"),
                            })}
                        />
                        <InputField
                            id="shortDesc"
                            value={formik.values.shortDesc ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="shortDesc"
                            labelText="Donation Info"
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="shortDesc" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "shortDesc"),
                            })}
                        />
                        <InputField
                            id="longDesc"
                            value={formik.values.longDesc ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="longDesc"
                            labelText="Description"
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="longDesc" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "longDesc"),
                            })}
                            multiline
                        />
                        <InputNumberField
                            id="charityTargetValue"
                            inputId="charityTargetValue"
                            mode="currency"
                            currency="SMP"
                            value={formik.values.charityTargetValue ?? 0}
                            required
                            onValueChange={formik.handleChange}
                            labelName="charityTargetValue"
                            labelText="Target Value"
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="charityTargetValue" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "charityTargetValue"),
                            })}
                        />
                        {!isAddItem && (
                            <DropdownField
                                id="status"
                                name="status"
                                value={formik.values.status ?? ""}
                                required
                                autoFocus={true}
                                onChange={formik.handleChange}
                                options={statusOptions}
                                filter={true}
                                labelName="status"
                                labelText="Status"
                                getFormErrorMessage={() => <ErrorField formik={formik} name="status" />}
                                className={classNames({
                                    "p-invalid": isFormFieldValid(formik, "status"),
                                })}
                            />
                        )}
                    </>
                ) : (
                    <>
                        <InputField
                            id="charityShareCode"
                            value={formik.values.charityShareCode ?? ""}
                            type="text"
                            required
                            onChange={(e) => {
                                const value = e.target.value.replace(/\s/g, '');
                                formik.setFieldValue('charityShareCode', value);
                            }}
                            labelName="charityShareCode"
                            labelText="Share Code"
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="charityShareCode" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "charityShareCode"),
                            })}
                        />
                        <InputField
                            id="charityShareMessageTitle"
                            value={formik.values.charityShareMessageTitle ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="charityShareMessageTitle"
                            labelText="Mobile Share Title"
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="charityShareMessageTitle" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "charityShareMessageTitle"),
                            })}
                        />
                        <InputField
                            id="charityShareMessageText"
                            value={formik.values.charityShareMessageText ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="charityShareMessageText"
                            labelText="Mobile Share Text"
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="charityShareMessageText" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "charityShareMessageText"),
                            })}
                            multiline
                        />
                        <InputField
                            id="charityShareTitle"
                            value={formik.values.charityShareTitle ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="charityShareTitle"
                            labelText="Web Page Title"
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="charityShareTitle" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "charityShareTitle"),
                            })}
                        />
                        <InputField
                            id="charityShareMessage"
                            value={formik.values.charityShareMessage ?? ""}
                            type="text"
                            required
                            onChange={formik.handleChange}
                            labelName="charityShareMessage"
                            labelText="Web Page Text"
                            getFormErrorMessage={() => (
                                <ErrorField formik={formik} name="charityShareMessage" />
                            )}
                            className={classNames({
                                "p-invalid": isFormFieldValid(formik, "charityShareMessage"),
                            })}
                            multiline
                        />
                    </>
                )
                }
            </form>
        </Dialog>
    );
};

export default CharityDialog;

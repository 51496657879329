import { ICharity } from "@/models";
import React, { useEffect, useState } from "react";
import { Column, DataTable, Dialog } from "primereact";
import { DataTableDialogFooter, DataTableStatusTemplate } from "@/components";

interface CharitySortDialogProps {
    visible: boolean;
    charities: Array<Omit<ICharity, "imageUrl">>;
    isLoading: boolean;
    onHide: () => void;
    orderCharities: (charities: Array<Omit<ICharity, "imageUrl">>) => void;
}

const CharitySortDialog: React.FC<CharitySortDialogProps> = ({
    visible,
    charities,
    isLoading,
    onHide,
    orderCharities,
}) => {

    const [sortingCharities, setSortingCharities] = useState<Array<Omit<ICharity, "imageUrl">>>([]);

    useEffect(() => {
        setSortingCharities([...charities]);
    }, [charities]);

    const hideDialog = () => {
        onHide();
    };

    const handleSubmit = () => {
        orderCharities(sortingCharities);
        hideDialog();
    };

    const handleSortingCharities = (e: any) => {
        const newSortingCharities = e.map(
            (charity: Omit<ICharity, "imageUrl">, index: number) => {
                return {
                    ...charity,
                    sequence: index + 1,
                };
            },
        );
        setSortingCharities([...newSortingCharities]);
    };

    return (
        <Dialog
            visible={visible}
            style={{ minWidth: "450px", width: "80%" }}
            header="Charity Sort"
            modal
            className="p-fluid"
            footer={
                <DataTableDialogFooter
                    loading={isLoading}
                    hideDialog={hideDialog}
                    handleSubmit={handleSubmit}
                />
            }
            onHide={hideDialog}
        >
            <DataTable
                value={sortingCharities}
                title="charities"
                paginator={false}
                loading={isLoading}
                reorderableRows
                onRowReorder={(e) => handleSortingCharities(e.value)}
            >
                <Column rowReorder style={{ width: "3rem" }} />
                <Column
                    field="sequence"
                    header={"Sequence"}
                    style={{ minWidth: "12rem" }}
                ></Column>
                <Column field="name" header={"Name"} style={{ minWidth: "12rem" }}></Column>
                <Column
                    field="status"
                    header={"Status"}
                    body={(rowData: ICharity) => (
                        <DataTableStatusTemplate status={rowData.status} />
                    )}
                    style={{ minWidth: "12rem" }}
                ></Column>
            </DataTable>
        </Dialog>
    );
};

export default CharitySortDialog;

import { useEffect, useRef, useState } from "react";
import {
  DrawDialog,
  DataTableCrud,
  CampaignDialog,
  DataTableToolbar,
  CampaignSortDialog,
  CampaignImagesDialog,
  DataTableSearchHeader,
  DataTableDeleteDialog,
  DataTableUpdateDialog,
  DataTableActionTemplate,
  SingleImageUploadDialog,
  CampaignPopupImagesDialog,
} from "@/components";
import {
  Toast,
  Button,
  Column,
  Tooltip,
  Dropdown,
  InputNumber,
  ColumnFilterElementTemplateOptions,
} from "primereact";
import {
  useAddCampaignData,
  useGetCampaignsData,
  useUpdateCampaignData,
  useDeleteCampaignData,
  useOrderCampaignsData,
  useAddCampaignImagesData,
  useDeleteCampaignImageData,
  useUpdateCampaignStatusData,
  useAddCampaignPopupImagesData,
} from "@/hooks";
import { ICampaign, IMbi } from "@/models";
import { initialCampaign } from "../../../../constants/initialValues";
import {
  campaignFilters,
  campaignTypes,
  STATUS,
  statusTypes,
} from "@/constants";
import { useTranslation } from "react-i18next";
import { CAMPAIGN_TYPES } from "@/constants/enums";

const CampaignDataTable = () => {
  const [campaign, setCampaign] =
    useState<Omit<ICampaign, "imageUrl">>(initialCampaign);
  const [deleteCampaignDialog, setDeleteCampaignDialog] = useState(false);
  const [updateCampaignStatusDialog, setUpdateCampaignStatusDialog] = useState(false);
  const [imageDialog, setImageDialog] = useState(false);
  const [popupImageDialog, setPopupImageDialog] = useState(false);
  const [campaignDialog, setCampaignDialog] = useState(false);
  const [campaignSortDialog, setCampaignSortDialog] = useState(false);
  const [isAddItem, setIsAddItem] = useState(false);
  const [filters, setFilters] = useState(campaignFilters);

  const [drawDialogVisible, setDrawDialogVisible] = useState(false);

  const [selectedCampaign, setSelectedCampaign] = useState<ICampaign | null>(
    null,
  );
  const toast = useRef<Toast>(null);
  const { t } = useTranslation();

  const formData = new FormData();

  const { campaigns, getCampaignsIsLoading } = useGetCampaignsData();
  const {
    mutate: addCampaignMutate,
    addCampaignIsLoading,
    addCampaignIsSuccess,
  } = useAddCampaignData(toast);
  const {
    mutate: updateCampaignMutate,
    updateCampaignIsLoading,
    updateCampaignIsSuccess,
  } = useUpdateCampaignData(toast);
  const { mutate: deleteCampaignMutate } = useDeleteCampaignData(toast);
  const { mutate: updateCampaignStatusMutate } = useUpdateCampaignStatusData(toast);
  const { mutate: addCampaignImagesMutate,
    addCampaignImagesIsLoading,
    addCampaignImagesIsSuccess
  } = useAddCampaignImagesData(toast);
  const { mutate: addCampaignPopupImagesMutate,
    addCampaignPopupImagesIsLoading,
    addCampaignPopupImagesIsSuccess
  } = useAddCampaignPopupImagesData(toast);
  const { mutate: deleteCampaignImageMutate } =
    useDeleteCampaignImageData(toast);
  const { mutate: orderCampaignMutate } = useOrderCampaignsData(toast);

  useEffect(() => {
    if (addCampaignIsSuccess || updateCampaignIsSuccess) {
      hideCampaignDialog();
    }
  }, [addCampaignIsSuccess, updateCampaignIsSuccess]);


  useEffect(() => {
    if (addCampaignImagesIsSuccess) {
      hideImageDialog();
    }
  }, [addCampaignImagesIsSuccess]);

  useEffect(() => {
    if (addCampaignPopupImagesIsSuccess) {
      hidePopupImageDialog();
    }
  }, [addCampaignPopupImagesIsSuccess]);

  const openDeleteCampaignDialog = (campaign?: ICampaign) => {
    campaign && setCampaign(campaign);
    setDeleteCampaignDialog(true);
  };

  const openUpdateCampaignStatusDialog = (campaign?: ICampaign) => {
    campaign && setCampaign(campaign);
    setUpdateCampaignStatusDialog(true);
  };

  const openAddCampaignDialog = () => {
    setCampaign(initialCampaign);
    setIsAddItem(true);
    setCampaignDialog(true);
  };

  const hideDeleteCampaignDialog = () => {
    setDeleteCampaignDialog(false);
  };

  const hideUpdateCampaignStatusDialog = () => {
    setUpdateCampaignStatusDialog(false);
  };

  const hideImageDialog = () => {
    setImageDialog(false);
  };

  const hidePopupImageDialog = () => {
    setPopupImageDialog(false);
  };

  const openImageCampaigntDialog = (rowData: ICampaign) => {
    setCampaign({ ...rowData });
    setImageDialog(true);
  };

  const openPopupImageDialog = (rowData: ICampaign) => {
    setCampaign({ ...rowData });
    setPopupImageDialog(true);
  };

  const openDrawDialog = (campaign: ICampaign) => {
    setSelectedCampaign(campaign);
    setDrawDialogVisible(true);
  };

  const hideDrawDialog = () => {
    setDrawDialogVisible(false);
  };

  const openCampaignSortDialog = () => {
    setCampaignSortDialog(true);
  };

  const deleteCampaign = () => {
    campaign.id && deleteCampaignMutate(campaign.id);
    hideDeleteCampaignDialog();
  };

  const updateCampaignStatus = () => {
    campaign.id && updateCampaignStatusMutate({
      campaignId: campaign.id,
      status: campaign.status === STATUS.ACTIVE ? STATUS.INACTIVE : STATUS.ACTIVE
    });
    hideUpdateCampaignStatusDialog();
  };

  const openEditCampaignDialog = (rowData: ICampaign) => {
    const { imageUrl, ...rest } = rowData;
    setCampaign({ ...rest });
    setIsAddItem(false);
    setCampaignDialog(true);
  };

  const saveCampaign = (newCampaign: Omit<ICampaign, "imageUrl">) => {
    isAddItem
      ? addCampaignMutate(newCampaign)
      : updateCampaignMutate(newCampaign);
  };

  const hideCampaignDialog = () => {
    setCampaignDialog(false);
  };

  const saveImage = (rowData: IMbi | ICampaign | null, file: FileList) => {
    formData.append("campaignId", rowData?.id?.toString() ?? "");
    formData.append("file", file[0]);
    addCampaignImagesMutate({ campaignImages: formData });
  };

  const savePopupImages = (rowData: ICampaign | null, form: FormData) => {
    addCampaignPopupImagesMutate({ campaignId: rowData?.id?.toString() ?? null, popupImages: form });
  };

  return (
    <>
      <Toast ref={toast} />

      <DataTableToolbar
        disableDeleteButton={!selectedCampaign}
        openDeleteDialog={openDeleteCampaignDialog}
        openAddDialog={openAddCampaignDialog}
      >
        <Button
          id="p-sort-button"
          label="Sort"
          icon="pi pi-sort"
          className="p-button-primary mr-2"
          onClick={openCampaignSortDialog}
        />
      </DataTableToolbar>

      <DataTableCrud
        value={campaigns}
        title="campaigns"
        selection={selectedCampaign}
        filters={filters}
        filterDisplay="row"
        globalFilterFields={[
          "name",
          "description",
          "limitValue",
          "bonus",
          "type",
          "status",
        ]}
        emptyMessage={t("No campaigns found.")}
        loading={getCampaignsIsLoading}
        onSelectionChange={(e: any) => setSelectedCampaign(e.value)}
        header={
          <DataTableSearchHeader
            title={"Manage Campaigns"}
            filters={filters}
            onChange={(e: any) => setFilters(e)}
          />
        }
      >
        <Column
          field="name"
          header={"Name"}
          sortable
          style={{ minWidth: "12rem" }}
          filter
          filterPlaceholder={t("Search by name")}
        ></Column>
        <Column
          field="description"
          header={"Description"}
          sortable
        ></Column>
        <Column
          field="limitValue"
          header="Limit Value"
          sortable
        ></Column>
        <Column
          field="bonus"
          header={"Bonus (SMP)"}
          sortable
        ></Column>

        <Column
          field="type"
          header={"Type"}
          sortable
          filter
          showFilterMenu={false}
          filterPlaceholder={t("Search by type")}
          filterElement={(options: ColumnFilterElementTemplateOptions) => {
            return (
              <Dropdown
                value={options.value}
                options={campaignTypes}
                onChange={(e) => options.filterApplyCallback(e.value)}
                itemTemplate={(option: string) => {
                  return (
                    <span
                      className={`badge status-${option ? option.toLowerCase() : ""}`}
                    >
                      {option}
                    </span>
                  );
                }}
                valueTemplate={(option: string) => {
                  return (
                    <span
                      className={`badge status-${option ? option.toLowerCase() : ""}`}
                    >
                      {option}
                    </span>
                  );
                }}
                placeholder="Select a Status"
                className="p-column-filter"
              />
            );
          }}
        ></Column>

        <Column
          field="status"
          header="Status"
          sortable
          filter
          showFilterMenu={false}
          filterPlaceholder="Search by status"
          filterElement={(options: ColumnFilterElementTemplateOptions) => {
            return (
              <Dropdown
                value={options.value}
                options={statusTypes}
                onChange={(e) => options.filterApplyCallback(e.value)}
                itemTemplate={(option: string) => {
                  return (
                    <span
                      className={`badge status-${option ? option.toLowerCase() : ""}`}
                    >
                      {option}
                    </span>
                  );
                }}
                valueTemplate={(option: string) => {
                  return (
                    <span
                      className={`badge status-${option ? option.toLowerCase() : ""}`}
                    >
                      {option}
                    </span>
                  );
                }}
                placeholder="Select a Status"
                className="p-column-filter"
              />
            );
          }}
          body={(rowData: ICampaign) => (
            <Dropdown
              value={rowData.status}
              options={statusTypes}
              onChange={(e) => {
                e.target.value && openUpdateCampaignStatusDialog(rowData);
              }}
              itemTemplate={(option: string) => {
                return (
                  <span
                    className={`badge status-${option ? option.toLowerCase() : ""}`}
                  >
                    {option}
                  </span>
                );
              }}
              valueTemplate={(option: string) => {
                return (
                  <span
                    className={`badge status-${option ? option.toLowerCase() : ""}`}
                  >
                    {option}
                  </span>
                );
              }}
              placeholder="Select a Status"
              style={{ minWidth: "10rem" }}
            />
          )}
        />

        <Column
          body={(rowData: ICampaign) => (
            <>
              <DataTableActionTemplate
                openDeleteDialog={() => openDeleteCampaignDialog(rowData)}
                openEditDialog={() => openEditCampaignDialog(rowData)}
              />
              <Tooltip target="#p-images-button" position="bottom" content="Images" />
              <Tooltip
                target="#p-sort-button"
                position="bottom"
                content="Sort Campaigns"
              />
              <Button
                id="p-images-button"
                icon="pi pi-images"
                className="p-button-rounded m-1"
                onClick={() => {
                  setSelectedCampaign(rowData);
                  openImageCampaigntDialog(rowData);
                }}
              />
              {(rowData.type === "DRW" || rowData.type === "TBD") && (
                <>
                  <Tooltip target="#p-info-button" content="Info" position="bottom" />
                  <Button
                    id={"p-info-button"}
                    icon="pi pi-info-circle"
                    className="p-button-rounded p-button-secondary m-1"
                    onClick={() => openDrawDialog(rowData)}
                  />
                </>
              )}

              {(rowData.type === "DRT") && (
                <>
                  <Tooltip target="#p-popup-images-button" content="Pop-up Images" position="bottom" />
                  <Button
                    id="p-popup-images-button"
                    icon="pi pi-upload"
                    className="p-button-rounded p-button-help m-1"
                    onClick={() => {
                      setSelectedCampaign(rowData);
                      return openPopupImageDialog(rowData);
                    }}
                  />
                </>
              )}
            </>
          )}
          exportable={false}
          style={{ minWidth: "16rem" }}
        ></Column>
      </DataTableCrud>

      <DataTableDeleteDialog
        visible={deleteCampaignDialog}
        data={selectedCampaign ?? campaign}
        onHide={hideDeleteCampaignDialog}
        onDelete={deleteCampaign}
      />

      <DataTableUpdateDialog
        visible={updateCampaignStatusDialog}
        data={selectedCampaign ?? campaign}
        onHide={hideUpdateCampaignStatusDialog}
        onUpdate={updateCampaignStatus}
        text={`Are you sure you want to change status to ${campaign.status === STATUS.ACTIVE ? STATUS.INACTIVE : STATUS.ACTIVE}?`}
      />

      <CampaignDialog
        visible={campaignDialog}
        campaign={campaign}
        isLoading={isAddItem ? addCampaignIsLoading : updateCampaignIsLoading}
        onHide={hideCampaignDialog}
        addCampaign={saveCampaign}
        isAddItem={isAddItem}
        toast={toast}
      />

      <CampaignSortDialog
        visible={campaignSortDialog}
        campaigns={
          campaigns
            ?.filter((campaign) => campaign.status === STATUS.ACTIVE)
            .sort((a, b) => a.sequence - b.sequence) ?? []
        }
        isLoading={getCampaignsIsLoading}
        onHide={() => setCampaignSortDialog(false)}
        orderCampaigns={orderCampaignMutate}
      />

      {(selectedCampaign?.type !== CAMPAIGN_TYPES.DRW && selectedCampaign?.type !== CAMPAIGN_TYPES.TBD) && (<SingleImageUploadDialog
        rowData={selectedCampaign}
        visible={imageDialog}
        onHide={hideImageDialog}
        saveImage={saveImage}
        isLoading={addCampaignImagesIsLoading}
      />)}

      {(selectedCampaign?.type === CAMPAIGN_TYPES.DRW || selectedCampaign?.type === CAMPAIGN_TYPES.TBD) && (<CampaignImagesDialog
        visible={imageDialog}
        onHide={hideImageDialog}
        campaign={campaigns?.find(c => c.id === selectedCampaign.id) ?? null}
      />)}

      {(selectedCampaign?.type === CAMPAIGN_TYPES.DRT) && (<CampaignPopupImagesDialog
        visible={popupImageDialog}
        onHide={hidePopupImageDialog}
        savePopupImages={savePopupImages}
        campaign={campaigns?.find(c => c.id === selectedCampaign.id) ?? null}
      />)}

      <DrawDialog
        visible={drawDialogVisible}
        onHide={hideDrawDialog}
        campaign={selectedCampaign}
      />
    </>
  );
};

export default CampaignDataTable;
